import React from "react";
import sponsered from "../assets/localcoin/logo_sponsored.png"
import logo_spark_black from "../assets/localcoin/logo_sparklife_black.png"
import "../styles/Footer.css"

const Footer = () => {
    return (
        <>
            <div id="footer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-5 col-sm-3 offset-sm-2 align-self-center text-end pe-0">
                            <img className="sponsered" src={sponsered} alt="logo" />
                        </div>
                        <div className="col-6 col-sm-6 align-self-center ps-0">
                            <img className="sparklife" src={logo_spark_black} alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;