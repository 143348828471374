import React, { useState, useEffect } from "react";
import "../styles/BuyConfirmCard.css";
import { useParams, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import web3Utils from '../Utils/web-utils'
import { Puff } from 'react-loader-spinner'
import axios from 'axios'
import Talk from "talkjs"
import swal from 'sweetalert'
import { CheckCircle } from "react-feather";
import { Popover } from "bootstrap";
import TimeReserveTransaction from "./TimeReserveTransaction";
import { useTranslation } from 'react-i18next';

const BuySellConfirmCard = ({
  tokenListByAddress,
  buy_sell,
  date,
  idTrade,
  complete,
  one_pridetrade,
  trade_extra_info_payment,
  back_to,
  seller,
  trade,
  swBuyOrSell,
  sellerData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { id } = useParams();
  // const chatDiv = useRef();
  // var popup
  /* console.log(id); */

  // const [getId, setId] = useState(id);
  // const [getTrade, setTrade] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [feedBackRate, setFeedBackRate] = useState(0);
  const [bankSeller, setBankSeller] = useState({})

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })

  async function makeNewConversation(addressTo, id) {
    Talk.ready
      .then(() => {
        const to = new Talk.User({
          id: window.ethereum.selectedAddress,
          name: "Chat con " + window.ethereum.selectedAddress.substring(0, 5) + "..." + window.ethereum.selectedAddress.slice(-5),
          email: null,
          role: "sellers"
        })

        /*if (!window.talkSession) {
            window.talkSession = new Talk.Session({
                appId: "tiJYePJU",
                me: me,
            });
        }*/

        if (window.talkSession) {
          const me = new Talk.User({ // me es el seller para lograr construir el ID de la transaccion
            id: addressTo,
            name: "Chat con " + addressTo.substring(0, 5) + "..." + addressTo.slice(-5),
            email: null,
            role: "sellers"
          })

          // Crear conversacion con nuevo ID
          // Seller_buyer_id de la transaccion
          // debugger
          var conversation = window.talkSession.getOrCreateConversation(
            Talk.oneOnOneId(me, to)
          )
          conversation.setAttributes({ subject: "Chat Privado SPS" })
          conversation.setAttributes({
            custom:
            {
              orderId: id
            }
          })
          conversation.setParticipant(me)
          conversation.setParticipant(to)

          //window.talkSession.createInbox(conversation)
          /*popup = window.talkSession.createPopup();*/
          window.popupChat.select(conversation);
          //window.popupChat.mount(chatDiv.current);
        }
      })
      .catch(e => console.error(e))
  }

  async function getRate() {
    axios.defaults.headers['x-access-token'] = ''
    axios.get('https://localcoin.digital:8080/api/v1/user/' + seller + '/feedback/rate')
      .then((result) => {
        setFeedBackRate(result.data.rate)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat('es', m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  let a = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];


  /**
   * Evento Boton del formulario para aprobar o enviar saldo en la transaccion
   * @param {*} e 
   */
  const handlePut = async (e) => {
    e.defaultPrevented = true
    e.target.disabled = true
    setLoading(true)
    debugger
    const spsTokenContract = await web3Utils.getContract(trade.tokenTo, web3Utils.getAbiByAddress[trade.tokenTo])

    // Validamos que el contrato p2p tenga permisos para mover los fondos del token en cuestion
    await spsTokenContract.methods.allowance(window.ethereum.selectedAddress, await web3Utils.getP2PContractAddress())
      .call()
      .then(async ammount => {
        if (ammount > 0 && (
          await web3Utils.toBN(web3Utils.toWei(ammount.toString(), 'ether')) >= await web3Utils.toBN(web3Utils.toBN(web3Utils.toWei('10000000000', 'ether')))
        )
        ) {
          return pushOffert()
        }
        else {
          swal({
            title: `¡${t("confirm")}!`,
            text: t("q.use_token", { coin: trade.coin }),//"Para poder hacer uso del token " + trade.coin + " debes dar permiso al P2P para acceder a ellos. ¿Desea realizar la autorización ahora mismo?",
            icon: "warning",
            buttons: true,
            dangerMode: false,
          })
            .then(async result => {
              if (result) {
                const spsTokenContract = await web3Utils.getContract(trade.tokenTo, web3Utils.getAbiByAddress[trade.tokenTo])
                await spsTokenContract.methods.approve(await web3Utils.getP2PContractAddress(), '10000000000000000000')
                  .send({ from: window.ethereum.selectedAddress })
                  .then(async () => {
                    swal(t("r.good_job"), t("r.approved_balance"), "success")
                      .then(() => {
                        pushOffert()
                      })
                  })
              }
              else {
                e.target.disabled = false
                setLoading(false)
              }
            })
        }
      })
      .catch(err => {
        console.log(err)
        e.target.disabled = false
        setLoading(false)
      })

    /**
     * Funcion encargada de lanzar el evento de enviar oferta al contrato P2P
     */
    async function pushOffert() {
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      var txNetwork

      if (web3Utils.getUserSesionData().provider === 3)
        txNetwork = p2pContract.methods
          .offertEscrow(
            seller,
            id
          )
          .send({
            feeLimit: 500_000_000
          })
      else
        txNetwork = p2pContract.methods
          .offertEscrow(
            seller,
            id
          )
          .send({ value: '0', from: window.ethereum.selectedAddress })
          .on('transactionHash', hash => {
            console.log(hash)
          })
          .on('transactionHash', () => {
            console.log("Pending transaction... please wait")
          })
          .on('error', (err) => {
            console.log(err)
            setLoading(false)
            e.target.disabled = false
          })

      return await txNetwork
        .then(receipt => {
          console.log(receipt)
          // debugger
          // new chat
          makeNewConversation(seller, id)
          // si es 1, es porq se debe de alertar al comprador que le hicieron la oferta
          if (swBuyOrSell === '1') {
            axios.post('https://localcoin.digital:8080/api/v1/notify/' + seller + '?f=' + window.ethereum.selectedAddress + '&t=1&id=' + id + '&tp=' + trade.ammount * trade.pricetrade + '&ttn=' + trade.tokenToName + '&tsn=' + trade.tokenSwapName)
              .then(() => {
                setLoading(false)
                e.target.disabled = false
                swal(t("r.good_job"), t("r.transaction_complete_history"), "success")
                navigate("/History");
              })
              .catch(err => {
                console.log(err)
                swal(t("r.good_job"), t("r.transaction_complete_no_seller"), "warning")
                setLoading(false)
                e.target.disabled = false
                navigate("/History");
              })
          }
          else {
            // Soy un comprador
            // alertar al vendedor que alguien hizo una oferta (se supone que a este punto ya realizo la transferencia)
            axios.post('https://localcoin.digital:8080/api/v1/notify/' + window.ethereum.selectedAddress + '?f=' + seller + '&t=0&id=' + id + '&tp=' + trade.ammount * trade.pricetrade + '&ttn=' + trade.tokenToName + '&tsn=' + trade.tokenSwapName)
              .then(() => {
                setLoading(false)
                e.target.disabled = false
                swal(t("r.good_job"), t("r.transaction_complete_history"), "success")
                navigate("/History");
              })
              .catch(err => {
                console.log(err)
                swal(t("r.good_job"), t("r.transaction_complete_no_seller"), "info")
                setLoading(false)
                e.target.disabled = false
                navigate("/History");
              })
          }
        })
        .catch(e => {
          console.log(e)
          setLoading(false)
          swal(t("r.some_went_wrong"), t("r.transaction_canceled"), "warning")
        })
    }

  };

  const removeOfferToEscrow = async (id) => {
    return new Promise(async (resolve, reject) => {

      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      var txNetwork
      if (web3Utils.getUserSesionData().provider === 3)
        txNetwork = p2pContract.methods
          .removeOfferToEscrow(
            id
          )
          .send({ feeLimit: 500_000_000 })
      else
        txNetwork = p2pContract.methods
          .removeOfferToEscrow(
            id
          )
          .send({ value: '0', from: window.ethereum.selectedAddress })
          .on('transactionHash', hash => {
            console.log(hash)
          })
          .on('transactionHash', () => {
            console.log("Pending transaction... please wait")
          })
          .on('error', (err) => {
            console.log(err)
            reject()
          })

      return txNetwork
        .then(receipt => {
          console.log(receipt)
          navigate("/BuySps");
          swal(t("r.order_released"), "info")
        })
        .catch(e => {
          console.log(e)
          swal(t("r.some_went_wrong"), t("r.transaction_canceled"), "warning")
        })
    })
  }

  /**
   * Devuelve el banco que el seller dispuso para esta transacción
   * Si la transacción tiene un banco elegido se escoge, sino elige 
   * el banco que el usuario tenia seleccionado
   */
  const getBankSeller = () => {
    axios.defaults.headers['x-access-token'] = '' //dawd 
    axios.get('https://localcoin.digital:8080/api/v1/user/' + seller + '/bank')
      .then((result) => {
        console.log(trade_extra_info_payment)
        console.log(result.data.bank)
        if (trade_extra_info_payment)
          setBankSeller(result.data.banks.find(bank => bank._id === trade_extra_info_payment))
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    // setTrade(trade);
    getRate();
    getBankSeller()
  }, [trade_extra_info_payment]);

  return (
    <>
      <div id="buySellConfirmCard" className="container">

        <h2 className="text-center">
          {buy_sell} {trade.coin} a {seller.substring(0, 5) + "..." + seller.slice(-5)}&nbsp;
          {feedBackRate === 100 ?
            <CheckCircle
              data-bs-toggle="popover"
              data-bs-custom-class="custom-tooltip"
              data-bs-title={t("verified_merchant")}
              data-bs-content={`${t("qualification")} ${feedBackRate}/100`} />
            : <></>
          }

        </h2>
        <hr></hr>
        <div className="row">
          <h3 className="col-12">{t("order_information")}</h3>
          <div className="col-12 col-sm-5">
            <div className="row">
              <p>
                {t("order_number")}:{idTrade}<br />
                {t("creation_date")}: {join(new Date(date), a, '-')}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-7">
            <p>
              {t("amount")}: <b>{trade.ammount} {trade.coin}</b><br />
              {t("price")}: {trade.coin}: <b>{new Intl.NumberFormat('de-DE', {}).format(trade.pricetrade)} {trade.tokenSwapName}</b>
            </p>
            <h4>{t("total")}:: <b style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('de-DE', {}).format(trade.pricetrade * trade.ammount)} {trade.tokenSwapName}</b></h4>
          </div>
        </div>
        <hr></hr>

        <div className="row text-center">
          {swBuyOrSell === '0' &&
            <div className="col-12">
              <div className="row">
                <h6 className="col-12">
                  {t("text.transfer_account_seller")}
                </h6>
              </div>
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-11 col-sm-6 col-md-4 card text-center">
                    <div className="card-header">
                      <h6 className="card-subtitle">{t("bank")}:</h6>
                      <h3 className="card-title">{bankSeller.name ? bankSeller.name : sellerData.bank}</h3>
                    </div>
                    <div className="card-body">
                      <h6 className="card-subtitle">{t("number")}: <span><b>{bankSeller.name ? bankSeller.account : sellerData.numberAccount}</b></span></h6>
                      {bankSeller.ben &&
                        <h6 className="card-subtitle">{t("ben")}: {bankSeller.ben}</h6>}
                    </div>
                    <div className="card-footer">
                      <h6 className="card-subtitle">{t("name")} {t("seller")} <span><b>{sellerData.name}</b></span></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          {swBuyOrSell === '0' ?
            <div>
              <p>
                {t("text.click_seller_pay")}
              </p>
              <div className="text-center">
                <button onClick={handlePut} className="btn btn-primary">
                  {!isLoading ? complete : ''}
                  {isLoading === true ?
                    <div className="SparklifeLoading">
                      <Puff
                        height="30"
                        width="30"
                        radisu={1}
                        color="#f8359e"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                    : ""}
                </button>
                <TimeReserveTransaction seller={seller} id={idTrade} />
                {t("text.chat_seller")}
              </div>
            </div>
            : <div>
              <h2>
                {t("text.confirm_transfer_notify", { coin: trade.coin })}
              </h2>
              <div>
                <button onClick={handlePut} className="btn btn-primary">
                  {!isLoading ? complete : ''}
                  {isLoading === true ?
                    <div className="SparklifeLoading">
                      <Puff
                        height="30"
                        width="30"
                        radisu={1}
                        color="#f8359e"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                    : ""}
                </button>
              </div>
            </div>}
        </div>
      </div >
    </>
  );
};

export default BuySellConfirmCard;
