import React, { useState, useEffect } from "react";
import web3Utils from '../../Utils/web-utils'
import "../../styles/BuyOffers.css";
import { Puff } from 'react-loader-spinner'
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next';

const BuyOffers = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [coin, setCoin] = useState(4);
  const [tokenSwap, setTokenSwap] = useState(2);
  const [tokenNamesById, setTokenNamesById] = useState([])
  const [isLoading, setLoading] = useState(false);

  const [weiAmmount, setWeiAmmount] = useState("")
  const [escrowAgentAddress, setEscrowAgentAddress] = useState("0x47b833781a04AB68777f0C3881009b0103e3666A")
  const [notes, setNotes] = useState("")
  const [type, setType] = useState("0")
  const [copPrice, setCopPrice] = useState("")
  const [tokenAddressById, setTokenAddressById] = useState([])

  const [tokenToName, setTokenToName] = useState("")
  const [tokenSwapName, setTokenSwapName] = useState("")
  const [tokenList, setTokenList] = useState([{}])

  var newEscrow = async (e) => {
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi)

    let weiAmmountSwap = weiAmmount * copPrice

    let spsAmmount = web3Utils.toWei(weiAmmount, 'ether')
    let priceWei = web3Utils.toWei(copPrice, 'ether')
    weiAmmountSwap = web3Utils.toWei(weiAmmountSwap.toString(), 'ether')

    if (coin === 4) { // si es sps, restamos decimales (10 decimales)
      spsAmmount = spsAmmount.substr(0, spsAmmount.length - 10)
    }

    return await p2pContract.methods
      .newEscrowSellOrBuy(
        spsAmmount,
        escrowAgentAddress,
        web3Utils.fromAscii(notes),
        type,
        priceWei,
        parseInt(tokenSwap),
        coin,
        weiAmmountSwap
      )
      .send({ value: '0', from: window.ethereum.selectedAddress })
      .on('transactionHash', hash => {
        console.log(hash)
      })
      .on('transactionHash', () => {
        console.log("Pending transaction... please wait")
      })
      .on('error', (err) => {
        console.log(err);
        setLoading(false)
      })
      .then(receipt => {
        console.log(receipt);
        setLoading(false)
        swal("¡Buen trabajo!", "Se ha completado la oferta, puedes verificarla en la pantalla de ofertas de compra.", "success")
        e.target.reset();
        navigate("/History");
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // await window.ethereum.enable();

    var abi = web3Utils.getAbiByAddress[tokenAddressById[coin]] // abi tokenTo
    var addressTokenTo = tokenAddressById[coin]
    const spsTokenContract = await web3Utils.getContract(addressTokenTo, abi);
    await spsTokenContract.methods.allowance(window.ethereum.selectedAddress, await web3Utils.getP2PContractAddress())
      .call()
      .then(async ammount => {
        if (ammount > 0 && (
          await web3Utils.toBN(web3Utils.toWei(ammount.toString(), 'ether')) >= await web3Utils.toBN(web3Utils.toBN(web3Utils.toWei('10000', 'ether')))
        )
        ) {
          return newEscrow(e)
        }
        else {
          swal({
            title: `¡${t("confirm")}!`,
            text: t("q.use_token", { coin: tokenNamesById[coin] }),
            icon: "warning",
            buttons: true,
            dangerMode: false,
          })
            .then(async result => {
              if (result) {
                const spsTokenContract = await web3Utils.getContract(addressTokenTo, abi);
                await spsTokenContract.methods.approve(await web3Utils.getP2PContractAddress(), '1000000000000000000000000').send({ from: window.ethereum.selectedAddress })
                  .then(() => {
                    swal(t("r.good_job"), t("r.balance_approved_offer"), "success")
                    return newEscrow(e)
                  })
              }
              else {
                e.target.disabled = false
                setLoading(false)
              }
            })
        }
      })
      .catch(err => {
        console.log(err)
        e.target.disabled = false
        setLoading(false)
      })

    /*fetch("https://sps-p2p.herokuapp.com/trades.json", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then(() => {
      console.log("success");
    });
    console.log(data);
    console.log(JSON.stringify(data)); */

  };

  useEffect(() => {
    web3Utils.getTokentList()
      .then(list => {
        setTokenList(list)
        let tokenById = []
        let tokenAddressById = []
        let i = 0
        list.forEach(token => {
          tokenById[token.id] = token.name
          tokenAddressById[token.id] = token.address
          i++
          if (list.length === i) {
            setTokenNamesById(tokenById)
            setTokenAddressById(tokenAddressById)
            setTokenToName(tokenById[4])
            setTokenSwapName(tokenById[2])
          }
        })

      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  return (
    <>
      <div className="banner-P2P"><h1>P2P</h1><h2>Compra Sparklife</h2></div>

      <div className="BuyOffers-Main-Container">
        <h1>NUEVA OFERTA DE COMPRA</h1>
        <div className="Form-Container">
          <form onSubmit={handleSubmit} className="Form-Container">
            <div className="Form-Input-Container">
              <label> Activo a Comprar </label>
              <select
                style={{ width: "53%" }}
                value={coin}
                onChange={(e) => {
                  setCoin(e.target.value)
                  setTokenToName(tokenNamesById[e.target.value])
                }}
                className="Form-Select"
              >
                {tokenList.map((token, index) => {
                  if (index > 1) {
                    return (
                      <option
                        value={token.id}
                        key={token.id}>
                        {token.name}
                      </option>
                    )
                  }
                })
                }
              </select>
            </div>
            <div className="Form-Input-Container">
              <label>Cantidad</label>
              <input
                style={{ width: "50%" }}
                value={weiAmmount}
                onChange={(e) => setWeiAmmount(e.target.value)}
                className="Form-Input"
                type="number"
                required
              />
            </div>
            <div className="Form-Input-Container">
              <label> Agente Validador </label>
              <select
                style={{ width: "53%" }}
                value={escrowAgentAddress}
                onChange={(e) => setEscrowAgentAddress(e.target.value)}
                className="Form-Select"
              >
                <option value="0x47b833781a04AB68777f0C3881009b0103e3666A">Agente SPS 1</option>
              </select>
            </div>
            <div className="Form-Input-Container">
              <label>Notas</label>
              <input
                style={{ width: "50%" }}
                maxlength="20"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="Form-Input"
                type="text"
              />
            </div>
            <div className="Form-Input-Container">
              <label> Activo de Intercambio</label>
              <select
                style={{ width: "53%" }}
                value={tokenSwap}
                onChange={(e) => {
                  setTokenSwap(e.target.value)
                  setTokenSwapName(tokenNamesById[e.target.value])
                }}
                className="Form-Select"
              >
                {tokenList.map((token, index) => {
                  //if(index > 1) {
                  return (
                    <option
                      value={token.id}
                      key={token.id}>
                      {token.name}
                    </option>
                  )
                  //}
                })
                }
              </select>
            </div>
            <div className="Form-Input-Container">
              <label>Precio de <b>{tokenToName}</b> en <b>{tokenSwapName}</b></label>
              <input
                style={{ width: "50%" }}
                value={copPrice}
                onChange={(e) => setCopPrice(e.target.value)}
                className="Form-Input"
                type="number"
                required
              />
            </div>

            {!isLoading ?
              <button className="Form-btn">Crear oferta</button>
              : ""}
            {isLoading === true ?
              <div className="SparklifeLoading">

                <Puff
                  height="30"
                  width="30"
                  radisu={1}
                  color="#f8359e"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />

              </div> : ""}
          </form>
        </div>


      </div>
    </>
  );
};

export default BuyOffers;
