import React, { useState, useEffect } from 'react'
import web3Utils from '../Utils/web-utils'
import { Dropdown } from "bootstrap";
import { RefreshCw } from 'react-feather';
import Select from 'react-select';
import coins from "../assets/monedas/index"

const OptionsBar = ({ tittleBar, tokenList, onFilterChange, onSortBy }) => {

  let filter1, filter2
  if (web3Utils.getUserSesionData().provider !== 3) {
    filter1 = "0x8033064Fe1df862271d4546c281AfB581ee25C4A"
    filter2 = "0x55d398326f99059fF775485246999027B3197955"
  }
  else {
    filter1 = "41c09b0801d2114bf549075f40ddda0a2ec31f97f9"
    filter2 = "41a614f803b6fd780986a42c78ec9c7f77e6ded13c"
  }

  const [filterCrypto, setFilterCrypto] = useState(filter1)
  const [filterCryptoSelected, setFilterCryptoSelected] = useState(null)
  const [filterPayMethod, setFilterPayMethod] = useState(filter2)
  const [filterPayMethodSelected, setFilterPayMethodSelected] = useState(null)
  const [priceOrderBy, setPriceOrderBy] = useState(1) // 0 asc, 1 desc

  const changeFilter = (crypto, paymethod) => {
    onFilterChange(crypto, paymethod)
  }

  const changeOrderPrice = (_priceOrderBy) => {
    onSortBy(_priceOrderBy)
  }

  const handleChangeCrypto = (e, auxPayMethod = -1) => {
    setFilterCrypto(e.value)
    setFilterCryptoSelected(e)
    changeFilter(e.value, auxPayMethod >= 0 ? auxPayMethod : filterPayMethod)
  }

  const handleChangeMetodoPago = (e, auxCrypto = -1) => {
    setFilterPayMethod(e.value)
    setFilterPayMethodSelected(e)
    changeFilter(auxCrypto >= 0 ? auxCrypto : filterCrypto, e.value)
  }



  // const handleChangeCrypto = (e, auxPayMethod = -1) => {
  //   setFilterCrypto(e.target.value)
  //   changeFilter(e.target.value, auxPayMethod >= 0 ? auxPayMethod : filterPayMethod)
  // }

  // const handleChangeMetodoPago = (e, auxCrypto = -1) => {
  //   setFilterPayMethod(e.target.value)
  //   changeFilter(auxCrypto >= 0 ? auxCrypto : filterCrypto, e.target.value)
  // }

  function handleClickIntercambio() {
    const auxCrypto = filterCryptoSelected
    const auxPayMethod = filterPayMethodSelected
    handleChangeCrypto(auxPayMethod, filterCrypto);
    handleChangeMetodoPago(auxCrypto, filterPayMethod)
  }

  return (
    <>
      <div id="optionsBar" className="container">
        <div className="row">
          <div className='col-12 col-sm-4'>
            {/* <div className='row buy-option'>
              <label className="col-5 col-sm-12 buy-tittle-label" htmlFor="sps">
                Crypto
              </label>
              <select
                className="col-6 col-sm-12 form-select-lg buy-selector"
                id="sps"
                name="spsPrice"
                form="spsForm"
                onChange={handleChangeCrypto}
                value={filterCrypto}
              >
                {tokenList.map((token, index) => {
                  return (
                    <option
                      value={token.address}
                      key={token.id}>
                      {token.name}
                    </option>
                  );
                })
                }
              </select>
            </div> */}
            <div className='row'>
              <label className="col-5 col-sm-12 buy-tittle-label" htmlFor="sps">
                Crypto
              </label>
              <Select
                className="col-6 col-sm-12"
                id="sps"
                name="spsPrice"
                // form="spsForm"
                onChange={handleChangeCrypto}
                value={filterCryptoSelected}
                options={
                  tokenList.map((token, index) => {
                    return ({
                      key: index,
                      value: token.address,
                      label: token.name,
                      image: coins[token.id]
                    });
                  })
                }
                formatOptionLabel={token => (
                  <div className="option-image">
                    <img src={token.image} />
                    <span>{token.label}</span>
                  </div>
                )}
              />
            </div>
          </div>
          <div className='col-6 col-sm-1 align-middle intercambio'>
            <div className='row buy-option'>
              <button className='btn btn-secondary' type="button" onClick={handleClickIntercambio}><RefreshCw /></button>
            </div>
          </div>
          <div className='col-12 col-sm-4'>
            {/*
            <div className='row buy-option'>
              <label className="col-5 col-sm-12 buy-tittle-label" htmlFor="pay">
                Método de pago
              </label>
              <select
                className="col-6 col-sm-12 form-select-lg buy-selector"
                id="pay"
                name="pay"
                form="payForm"
                onChange={handleChangeMetodoPago}
                value={filterPayMethod}
              >
                {tokenList.map((token, index) => {
                  return (
                    <option
                      value={token.address}
                      key={token.id}
                    >
                      {token.name}
                    </option>
                  );
                })
                }
              </select>
              </div>*/}

            <div className='row'>
              <label className="col-5 col-sm-12 buy-tittle-label" htmlFor="pay">
                Crypto
              </label>
              <Select
                className="col-6 col-sm-12"
                id="pay"
                name="pay"
                // form="spsForm"
                onChange={handleChangeMetodoPago}
                value={filterPayMethodSelected}
                options={
                  tokenList.map((token, index) => {
                    return ({
                      key: index,
                      value: token.address,
                      label: token.name,
                      image: coins[token.id]
                    });
                  })
                }
                formatOptionLabel={token => (
                  <div className="option-image">
                    <img src={token.image} />
                    <span>{token.label}</span>
                  </div>
                )}
              />
            </div>
          </div>
          <div className='col-12 col-sm-3'>
            <div className='row buy-option'>
              <label className="col-5 col-sm-12 buy-tittle-label" htmlFor="pay">
                Ordenar precio:
              </label>
              <select
                className="col-6 col-sm-12 form-select-lg buy-selector"
                id="pay"
                name="pay"
                form="payForm"
                onChange={(e) => {
                  setPriceOrderBy(e.target.value)
                  changeOrderPrice(e.target.value)
                }}
                value={priceOrderBy}
              >
                <option value="0" key="0">
                  Menor a mayor precio
                </option>
                <option value="1" key="1">
                  Mayor a menor precio
                </option>
              </select>
            </div>
          </div>

        </div>
      </div >
    </>
  )
}

export default OptionsBar