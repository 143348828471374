import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import Backend from 'i18next-fs-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import global_es from './assets/lang/es.json'
import global_en from './assets/lang/en.json'

const fallbackLng = 'es';
const availableLanguages = ['es', 'en'];

i18n
    // .use(Backend) // load translations using http (default  public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        // lng: lng,
        fallbackLng: fallbackLng, // fallback language is spanish.

        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },

        resources: {
            es: {
                translation: global_es
            },
            en: {
                translation: global_en
            }
        },
        // backend: {
        //     loadPath: `./assets/lang/{{lng}}.json`,
        // }
    });

export default i18n;