import React, { useState, useEffect } from "react";
import web3Utils from '../Utils/web-utils'
import "../styles/Table.css";
import { Puff } from 'react-loader-spinner'
import axios from 'axios'
import Talk from "talkjs"
import swal from 'sweetalert'
import feedbacklogo from "../assets/userfeedback.png"
import loading from "../assets/loading.gif"
import { useTranslation } from 'react-i18next';

const Table = (tokenListByAddress) => {
  const { t } = useTranslation();
  const [notRecords, setNotRecords] = useState(false)
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  var namesByAddress = []
  const parseList = async (response) => {
    try {
      let result = []
      let totalRows = response[0].length

      for (let i = 0; i < totalRows; i++) {
        if (response[1][i] !== "0") {
          result[i] = {}
          result[i]["id"] = parseInt(response[3][i])
          result[i]["typetrade"] = parseInt(response[4][i]) ? "Sell" : "Buy"
          result[i]["coin"] = namesByAddress[response[5][i]]
          result[i]["city"] = "dwad"
          result[i]["country_id"] = 1
          result[i]["currency"] = "USD"
          result[i]["user_first"] = ""
          result[i]["user_second"] = ""
          result[i]["payment_method_id"] = 1
          result[i]["extra_info_payment"] = "Nequi"
          result[i]["status_trade"] = web3Utils.toAscii(response[2][i]).replace(/\x00/g, "")
          result[i]["created_at"] = (new Date(response[6][i] * 1000).toLocaleString())
          result[i]["updated_at"] = "2022-04-28T17:38:52.347Z"
          result[i]["pricetrade"] = response[4][i] / 10 ** 18
          result[i]["ammount"] = response[1][i] / 10 ** web3Utils.getDecimalsByAddress[response[5][i]]
          result[i]["seller"] = response[0][i]
          result[i]["qualified"] = await getIdQualified(result[i]["id"], result[i]["seller"])
        }
      }
      return result
    } catch (err) {
      console.log(err)
      return []
    }
  }
  const [history, setHistory] = useState([]);

  async function fundRelease(seller, id, buyer, coin) {
    setIsLoadingAction(true)
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);

    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      txNetwork = p2pContract.methods
        .fundRelease(
          seller,
          id
        )
        .send({ feeLimit: 500_000_000 })
    else
      txNetwork = p2pContract.methods
        .fundRelease(
          seller,
          id
        )
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Pending transaction... please wait")
        })
        .on('error', (err) => {
          setIsLoadingAction(false)
          swal(t("r.no_approve_order"), "warning")
          console.log(err);
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt);
        getHistory()
        swal({
          text: t("q.experience_transaction"),
          icon: feedbacklogo,
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: [t("bad"), t("good")],
          dangerMode: false,
        })
          .then(async goodsad => {

            swal({
              text: t("please_wait"),
              icon: loading,
              closeOnClickOutside: false,
              closeOnEsc: false,
              buttons: false
            })

            // Enviar calificacion
            await axios.post('https://localcoin.digital:8080/api/v1/user/' + buyer + '/feedback', {
              comment: t("no_comment"),
              goodSad: goodsad,
              orderId: id,
              walletTo: seller
            })

            // Notificar al vendedor que ya se aprobaron los fondos
            axios.post('https://localcoin.digital:8080/api/v1/notify/' + buyer + '?f=' + seller + '&t=4&id=' + id + '&ttn=' + coin + '&tsn=')
              .then(() => {
                swal(t("r.good_job"), t("transaction_complete_history"), "success")
                setIsLoadingAction(false)
              })
              .catch(err => {
                console.log(err)
                swal("¡Ouch!", t("r.transaction_complete_no_seller"), "warning")
                setIsLoadingAction(false)
              })
          })
        return
      })
      .catch(e => {
        console.log(e)
        setIsLoadingAction(false)
        swal(t("r.some_went_wrong"), t("r.transaction_canceled"), "warning")
      })

  }

  async function EscrowAction(seller, id) {
    setIsLoadingAction(true)
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);

    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      txNetwork = p2pContract.methods
        .EscrowEscalation(
          seller,
          id
        )
        .send({ feeLimit: 500_000_000 })
    else
      txNetwork = p2pContract.methods
        .EscrowEscalation(
          seller,
          id
        )
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Pending transaction... please wait")
        })
        .on('error', (err) => {
          setIsLoadingAction(false)
          console.log(err);
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt);
        swal(t("r.good_job"), t("r.transaction_arbitrator"), "success")
        setIsLoadingAction(false)
      })
      .catch(e => {
        console.log(e)
        setIsLoadingAction(false)
      })
  }
  // Notificar al comprador (vista seller) que el deposito esta realizado, en este caso si el seller esta comprando SPS (vendiendo COP)
  // async function notifyPay(from, id, buyer, sellOrBuySw) {

  //   return new Promise(async (resolve, reject) => {
  //     let t = 1
  //     if(sellOrBuySw != "Sell")
  //       t = 0

  //     axios.post('https://localcoin.digital:8080/api/v1/notify/' + from + '?f=' + buyer + '&t=' + t + '&id=' + id + '&ttn=' + trade.coin+ '&tsn=')
  //     .then(() => {
  //       localStorage.setItem("ID_" + id + "_" + from, "1")
  //       swal("¡Buen trabajo!", "Se ha notificado al Comprador/Vendedor que se ha realizado el deposito. Luego de que este verifique el pago deberá proceder a liberar los fondos.", "success")
  //       resolve()
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       swal("¡Ouch!", "No se ha podido notificar al Comprador/Vendedor", "warning")
  //       resolve()
  //     })
  //   })

  // }

  const openChatWithBuyer = (sellerAddress, txId) => {

    const to = new Talk.User({
      id: window.ethereum.selectedAddress.toLowerCase(),
      name: "Chat con " + window.ethereum.selectedAddress.substring(0, 5) + "..." + window.ethereum.selectedAddress.slice(-5),
      email: null,
      role: "sellers"
    })

    const me = new Talk.User({ // en este caso "soy el seller" para construir el id de la transaccion
      id: sellerAddress.toLowerCase(),
      name: "Chat con " + sellerAddress.substring(0, 5) + "..." + sellerAddress.slice(-5),
      email: null,
      role: "sellers"
    })

    //let chatId = sellerAddress + "_" + window.ethereum.selectedAddress + "_" + txId
    var conversation = window.talkSession.getOrCreateConversation(
      Talk.oneOnOneId(me, to)
    )
    conversation.setParticipant(me)
    conversation.setParticipant(to)
    window.popupChat.select(conversation)
    window.popupChat.show()
  }

  const getHistory = async () => {
    setIsLoading(true)
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    return await p2pContract.methods
      .getBuyerTransaction(window.ethereum.selectedAddress, 1, 10)
      .call()
      .then(result => {
        setIsLoading(false)
        if (result[0][0] === '0x0000000000000000000000000000000000000000')
          setNotRecords(true)
        else
          setNotRecords(false)
        setHistory(parseList(result));
      })
      .catch(err => {
        console.log(err);
        setNotRecords(true)
        setIsLoading(false)
      });
  }

  const getIdQualified = async (id, who, sellerTx) => {
    return new Promise((resolve, reject) => {
      axios.get('https://localcoin.digital:8080/api/v1/user/feedback/qualified?orderId=' + id + '&walletTo=' + who)
        .then(qualified => {
          resolve(qualified.data.exists)
        })
    })
  }

  const qualifyUser = (id, whoTo, e) => {
    swal({
      text: t("q.experience_transaction"),
      icon: feedbacklogo,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: [t("bad"), t("good")],
      dangerMode: false,
    })
      .then(async goodsad => {

        swal({
          text: t("please_wait"),
          icon: loading,
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: false
        })

        // Enviar calificacion
        axios.post('https://localcoin.digital:8080/api/v1/user/' + window.ethereum.selectedAddress + '/feedback', {
          comment: t("rating_seller_buyer"),
          goodSad: goodsad,
          orderId: id,
          walletTo: whoTo
        })
          .then(() => {
            swal(t("r.good_job"), t("r.rating_correct"), "success")
            e.target.style.display = "none"
          })
          .catch(err => {
            swal("Ouch", t("r.rating_wrong"), "warning")
            console.log(err)
          })
      })
  }

  useEffect(() => {
    const getHistory = async () => {
      setIsLoading(true)
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      return await p2pContract.methods
        .getBuyerTransaction(window.ethereum.selectedAddress, 1, 10)
        .call()
        .then(async result => {
          setIsLoading(false)
          if (result[0][0] === '0x0000000000000000000000000000000000000000' || result[0][0] === undefined)
            setNotRecords(true)
          else
            setNotRecords(false)
          setHistory(await parseList(result));
        })
        .catch(err => {
          console.log(err);
          setNotRecords(true)
          setIsLoading(false)
        });
    }
    web3Utils.getTokentList()
      .then(result => {
        let i = 0
        result.forEach(token => {
          namesByAddress[token.address] = token.name
          i++
          if (result.length === i) {
            getHistory()
          }
        })
      })
  }, [])

  return (
    <>
      {isLoading === true ?
        <div style={{ left: "45%", position: "absolute" }}>
          <Puff
            height="30"
            width="30"
            radisu={1}
            color="#f8359e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> : ""}
      <div className="container table-responsive">
        <table className="table table-primary table-striped table-hover">
          <caption>{t("shopping_history")}</caption>
          <thead>
            <tr key="1">
              <th>{t("date_time")}</th>
              <th>{t("transaction")}</th>
              <th>{t("type")}</th>
              <th>{t("token")}</th>
              <th>{t("quantity")}</th>
              <th>{t("state")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {history.map((history, index) => {
              return (
                <tr key={index}>
                  <td>{history.created_at}</td>
                  <td>{history.id}</td>
                  <td>{history.typetrade}</td>
                  <td>{history.coin}</td>
                  <td>{history.ammount} </td>
                  <td>{history.status_trade === "En Progreso" ? t("in_progress") : history.status_trade === "Completado" ? t("complete") : history.status_trade}</td>
                  <td>
                    {(!isLoadingAction && history.typetrade.replace(/\x00/g, "") === "Buy" && history.status_trade === "En Progreso") ?
                      <button onClick={() => fundRelease(history.seller, history.id, window.ethereum.selectedAddress, history.coin)} className="btn btn-secondary">{t("approve")}</button> : ""} &nbsp;
                    <br />
                    {(!isLoadingAction && history.status_trade.replace(/\x00/g, "") === "En Progreso") ?
                      <button onClick={() => EscrowAction(history.seller, history.id)} className="btn btn-secondary">{t("request_intervention")}</button> : ""}
                    <br />
                    {!isLoadingAction && history.status_trade.replace(/\x00/g, "") === "En Progreso" ?
                      <button onClick={() => openChatWithBuyer(history.seller, history.id)} className="btn btn-secondary">{t("chat")} {t("seller")}</button>
                      : ""}
                    {!isLoadingAction && history.status_trade.replace(/\x00/g, "") === "Completado" && !history.qualified ?
                      <button onClick={(e) => qualifyUser(history.id, history.seller, e)} className="btn btn-secondary">{t("qualify")}</button>
                      : ""} &nbsp;
                    { // Sell (seller vende)
                  /*
                  (history.typetrade === "Sell" && history.status_trade === "En Progreso" && !localStorage.getItem("ID_" + history.id + "_" + window.ethereum.selectedAddress)) ? <button onClick={() => notifyPay(history.seller, history.id, window.ethereum.selectedAddress, history.typetrade)} className="button">Marcar como Deposito Realizado</button>
                  : "" */}
                    {isLoadingAction === true ?
                      <Puff
                        height="30"
                        width="30"
                        radisu={1}
                        color="#f8359e"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                      : ""}
                  </td>
                </tr>
              );
            })}

            {notRecords ? <tr><td colSpan={7}><h1 style={{ textAlign: "center" }}>{t("no_records")}</h1></td></tr> : ""}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
