import React, { useState, useEffect } from "react";
import web3Utils from '../Utils/web-utils'
import "../styles/Table.css";
import { Puff } from 'react-loader-spinner'
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next';

var namesByAddress = []
const Table = (tokenListByAddress) => {
  const { t } = useTranslation();
  const [isLoadingCancel, setLoadingCancel] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [notRecords, setNotRecords] = useState(false)

  const parseList = (response) => {
    try {
      let result = []
      let totalRows = response[0].length
      for (let i = 0; i < totalRows; i++) {
        if (response[1][i] !== "0") {
          result[i] = {}
          result[i]["id"] = parseInt(response[3][i])
          result[i]["typetrade"] = parseInt(response[0][i]) ? "Sell" : "Buy"
          result[i]["buyer"] = parseInt(response[4][i])
          result[i]["coin"] = namesByAddress[response[5][i]]
          result[i]["city"] = "dwad"
          result[i]["country_id"] = 1
          result[i]["currency"] = "USD"
          result[i]["user_first"] = ""
          result[i]["user_second"] = ""
          result[i]["payment_method_id"] = 1
          result[i]["extra_info_payment"] = "Nequi"
          result[i]["status_trade"] = web3Utils.toAscii(response[2][i]).replace(/\x00/g, "")
          result[i]["created_at"] = "2022-04-28T17:38:52.347Z"
          result[i]["updated_at"] = "2022-04-28T17:38:52.347Z"
          result[i]["pricetrade"] = 0
          result[i]["ammount"] = response[1][i] / 10 ** web3Utils.getDecimalsByAddress[response[5][i]]
          //result[i]["buyer"] = response[0][i]
          result[i]["buyOrSell"] = response[0][i]
        }
      }
      return result
    } catch (err) {
      console.log(err)
      return []
    }
  }
  const [history, setHistory] = useState([]);

  /**
   * Cancelar una orden de compra o venta
   * @param {*} id 
   * @returns 
   */
  async function cancelOrder(id) {
    setLoadingCancel(true)
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      txNetwork = p2pContract.methods
        .cancelOrder(
          id
        )
        .send({ feeLimit: 500_000_000 })
    else
      txNetwork = p2pContract.methods
        .cancelOrder(
          id
        )
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Pending transaction... please wait")
        })
        .on('error', (err) => {
          console.log(err);
          setLoadingCancel(false)
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt)
        getHistory()
        setLoadingCancel(false)
        swal("¡Buen trabajo!", "La transacción se ha cancelado con éxito.", "success")
      })
      .catch(e => {
        console.log(e)
        setLoadingCancel(false)
      })
  }

  /**
   * Remover la oferta (intencion de compra) de una orden
   * @param {*} seller 
   * @param {*} id 
   * @returns 
   */
  async function removeOfferToEscrow(id) {
    return new Promise(async (resolve, reject) => {

      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      var txNetwork
      if (web3Utils.getUserSesionData().provider === 3)
        txNetwork = p2pContract.methods
          .removeOfferToEscrow(
            id
          )
          .send({ feeLimit: 500_000_000 })
      else
        txNetwork = p2pContract.methods
          .removeOfferToEscrow(
            id
          )
          .send({ value: '0', from: window.ethereum.selectedAddress })
          .on('transactionHash', hash => {
            console.log(hash)
          })
          .on('transactionHash', () => {
            console.log("Pending transaction... please wait")
          })
          .on('error', (err) => {
            console.log(err)
            reject()
          })

      txNetwork
        .then(receipt => {
          console.log(receipt)
          getHistory()
          alert("Su orden ha sido liberada y desde ahora se enlistará nuevamente.")
        })
        .catch(e => {
          console.log(e)
          alert("Se ha cancelado la transacción")
        })
    })

  }

  const getHistory = async () => {
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    return await p2pContract.methods
      .getSellerOrdersToApproveOrActive(0, 30)
      .call({from: window.ethereum.selectedAddress})
      .then(result => {
        setIsLoading(false)
        if (result[0].length === 0 || result[1][0] === '0')
          setNotRecords(true)
        else
          setNotRecords(false)

        setHistory(parseList(result));
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getHistory = async () => {
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      return await p2pContract.methods
        .getSellerOrdersToApproveOrActive(0, 10)
        .call({ from: window.ethereum.selectedAddress })
        .then(result => {
          setIsLoading(false)
          if (result[0].length === 0 || result[1][0] === '0')
            setNotRecords(true)
          setHistory(parseList(result));
        })
        .catch(err => {
          console.log(err);
        });
    }

    web3Utils.getTokentList()
      .then(result => {
        let i = 0
        result.forEach(token => {
          namesByAddress[token.address] = token.name
          i++
          if (result.length === i) {
            getHistory()
          }
        })
      })
  }, []);


  return (
    <>
      {isLoading === true ?
        <div style={{ left: "45%", position: "absolute" }}>
          <Puff
            height="30"
            width="30"
            radisu={1}
            color="#f8359e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> : ""}
      <div className="container table-responsive">
        <table className="table table-primary table-striped table-hover">
          <caption>{t("open_orders")}</caption>
          <thead className="">
            <tr key="1">
              <th>{t("transaction")}</th>
              <th>{t("type")}</th>
              <th>{t("asset")}</th>
              <th>{t("state")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {history.map((history, index) => {
              return (
                <tr key={index}>
                  <td>{history.id}</td>
                  <td>{history.typetrade}</td>
                  <td>{history.ammount} {history.coin} </td>
                  <td>{history.status_trade === "En Progreso" ? t("in_progress") : history.status_trade === "Completado" ? t("complete") : history.status_trade}</td>
                  <td>
                    {!isLoadingCancel ?
                      <button onClick={() => cancelOrder(history.id)} className="btn btn-secondary">{t("cancel")}</button>
                      : ""} &nbsp;
                    <button onClick={() => removeOfferToEscrow(history.id)} className="btn btn-secondary">{t("cancel_offer")} {history.typetrade === "Sell" ? t("buyer") : t("seller")}</button>
                    {isLoadingCancel === true ?
                      <Puff
                        height="30"
                        width="30"
                        radisu={1}
                        color="#f8359e"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                      : ""}
                  </td>
                </tr>
              );
            })}
            {notRecords ? <tr><td colSpan={5}><h1 style={{ textAlign: "center" }}>{t("no_records")}</h1></td></tr> : ""}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
