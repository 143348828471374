import React, { useState, useEffect } from "react";
import "../../styles/AddBuyer.css";
import { Puff } from 'react-loader-spinner'
import axios from "axios";
import Banks from "../Banks"
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next';

const AddBankAccounts = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false)
  const [bankName, setBankName] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [bankList, setBankList] = useState([])
  const [isInternationalAccount, setIsInternationalAccount] = useState(false)
  const [bankBen, setBankBen] = useState("")

  useEffect(() => {
    getBankList();
  }, []);

  var validateBank = (e) => {
    if (
      bankName !== "" &&
      bankAccount !== "" &&
      bankAccount.length >= 8
    ) {
      if (isInternationalAccount)
        if (bankBen !== "")
          return true
        else
          return false
      return true
    }

    return false
  }

  /**
   * Agregar un nuevo vendedor
   * @param {*} e 
   */
  var addBankAccounts = async (e) => {

    // Validar datos
    if (!validateBank()) {
      alert("Por favor complete todos los campos")
      setLoading(false)
      return false
    }
    // TODO aún no guarda isInternational y ben
    axios.post('https://localcoin.digital:8080/api/v1/user/' + window.ethereum.selectedAddress + '/bank', {
      name: bankName,
      account: bankAccount,
      ben: isInternationalAccount ? bankBen : "",
    })
      .then(() => {
        setLoading(false)
        swal(t("r.good_job"), t("r.bank_created"), "success")
        setBankAccount("")
        setBankName("")
        setIsInternationalAccount(false)
        setBankBen("")
        // Actualizar el listado
        getBankList()
      })
      .catch(err => {
        console.log(err)
        swal("¡Ouch!", t("r.bank_no_created"), "success")
        setLoading(false)
      })
  }

  const handleCheckedInternational = (e) => {
    setIsInternationalAccount(e.target.checked)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    return addBankAccounts(e)
  };

  // TODO aún no guarda los valores del banco internacional
  const getBankList = async () => {
    axios.defaults.headers['x-access-token'] = '' //dawd 
    axios.get('https://localcoin.digital:8080/api/v1/user/' + window.ethereum.selectedAddress + '/bank')
      .then((result) => {
        setBankList(result.data.banks)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <div className="container text-center">

        <h5>{t("new_bank_account")}</h5>
        <div className="row ">
          <div className="col-12">
            <form onSubmit={handleSubmit}>
              <div className="row justify-content-center">
                <label className="col-12 col-sm-4 col-lg-3 text-sm-end" for="bankName">{t("name")}</label>
                <div className="col-12 col-sm-8 col-lg-4">
                  <input
                    id="bankName"
                    minLength={5}
                    maxLength={15}
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    className="form-control"
                    type="text"
                    oninput="this.value=this.value.replace(/[^A-Za-z\s]/g,'').slice(0,20);"
                    required
                  />
                </div>
              </div>
              <div className="row justify-content-center">
                <label className="col-12 col-sm-4 col-lg-3 text-sm-end" for="bankAccount">{t("account_number")}</label>
                <div className="col-12 col-sm-8 col-lg-4">
                  <input
                    id="bankAccount"
                    minLength={9}
                    maxLength={20}
                    min={0}
                    value={bankAccount}
                    onChange={(e) => {
                      if (e.target.value.length <= 19) {
                        setBankAccount(e.target.value)
                      }
                    }
                    }
                    className="form-control"
                    type="number"
                    title="Mínimo 9 caracteres"
                    required
                  />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-8 col-sm-5 col-lg-3 align-self-start">
                  <div className="form-check form-check-reverse">
                    <input className="form-check-input" type="checkbox" value="" id="isInternationalAccount"
                      checked={isInternationalAccount} onChange={handleCheckedInternational} />
                    <label className="form-check-label " for="isInternationalAccount">
                      {t("international_account")}
                    </label>
                  </div>
                </div>
              </div>
              {isInternationalAccount ?
                <div className="row justify-content-center">
                  <label className="col-12 col-sm-4 col-lg-3 text-sm-end" for="bankBen">{t("ben")}</label>
                  <div className="col-12 col-sm-8 col-lg-4">
                    <input
                      id="bankBen"
                      minLength={1}
                      maxLength={40}
                      min={0}
                      value={bankBen}
                      onChange={(e) => {
                        setBankBen(e.target.value)
                      }
                      }
                      className="form-control"
                      title="Mínimo 1 caracter"
                      required
                    />
                  </div>
                </div>
                : <></>
              }

              {!isLoading ?
                <button className="btn btn-secondary">{t("add")}</button>
                : ""}
              {isLoading === true ?
                <div style={{ margin: "20px 45%" }}>
                  <Puff
                    height="30"
                    width="30"
                    radisu={1}
                    color="#f8359e"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
                : ""}
            </form>
          </div>
        </div>
        <hr></hr>
        <h5>{t("my_banks")}</h5>
        <div className="row">
          {bankList.map((bank, index) => {
            return (
              <Banks
                key={index}
                name={bank.name}
                account={bank.account}
                id={bank._id}
                parentCallback={getBankList}
              />
            );
          })
          }
        </div>
      </div>

    </>
  );
};

export default AddBankAccounts;
