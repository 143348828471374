import React, { useState, useEffect } from "react";
import OptionsBar from "../OptionsBar";
import Offers from "../Offers";
import web3Utils from '../../Utils/web-utils'
import { Puff } from 'react-loader-spinner'
// import bannerp2presponsive from '../../assets/bannerp2p_responsive.png';

var isLoading = true
var notRecords = false
const SellSps = () => {

  const [buyTrades, setBuyTrades] = useState([]);
  const [buyTradesFilters, setBuyTradesFilters] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  // const [tokenSwap, setTokenSwap] = useState(2);
  // const [tokenNamesById, setTokenNamesById] = useState([])
  const [tokenNamesByAddress, setTokenNamesByAddress] = useState([])
  // const [tokenAddressById, setTokenAddressById] = useState([])
  // const [tokenToName, setTokenToName] = useState("")
  // const [tokenSwapName, setTokenSwapName] = useState("")

  var namesByAddress = []

  // Listado tipo sell typetrade=1 - para usuario final comprar
  const parseList = (response) => {
    let result = []
    let totalRows = response[0].length

    for (let i = 0; i < totalRows; i++) {
      if (response[2][i] !== 0) {
        result[i] = {}
        result[i]["id"] = parseInt(response[4][i])
        result[i]["typetrade"] = "Sell"
        result[i]["coin"] = namesByAddress[response[6][i]]
        result[i]["city"] = "dwad"
        result[i]["country_id"] = 1
        result[i]["currency"] = namesByAddress[response[1][i]]
        result[i]["user_first"] = ""
        result[i]["user_second"] = ""
        result[i]["payment_method_id"] = 1
        result[i]["extra_info_payment"] = ""
        result[i]["status_trade"] = web3Utils.toAscii(response[3][i]).replace(/\x00/g, "")
        result[i]["created_at"] = "2022-04-28T17:38:52.347Z"
        result[i]["updated_at"] = "2022-04-28T17:38:52.347Z"
        result[i]["pricetrade"] = response[2][i] / 10 ** web3Utils.getDecimalsByAddress[response[6][i]]
        result[i]["pricecop"] = parseFloat(response[5][i] / 10 ** 18)
        result[i]["seller"] = response[0][i]
        result[i]["tokenTo"] = response[6][i]
        result[i]["tokenSwap"] = response[1][i]
      }
    }
    // por defecto
    setBuyTradesFilters(result.filter(trade => trade.tokenTo === '0x0E035481F5579Df60eEd4456DF7c2b0740763bd0' && trade.tokenSwap === '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684'))
    return result
  }

  const parseTokenList = (response) => {
    let result = []
    let totalRows = response[0].length
    for (let i = 0; i < totalRows; i++) {
      result[i] = {}
      result[i]["id"] = parseInt(response[0][i])
      result[i]["name"] = web3Utils.toAscii(response[2][i]).replace(/\x00/g, "")
      result[i]["address"] = response[1][i]
    }
    return result
  }

  const onFilterChange = (token, paymethod) => {
    // debugger
    setBuyTradesFilters(buyTrades.filter(trade => trade.tokenTo === token && trade.tokenSwap === paymethod))
  }


  useEffect(() => {
    const getBuyTrades = async () => {
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      return await p2pContract.methods
        .marketBuyOrSellOrders(20, 0)
        .call()
        .then(result => {
          isLoading = false
          //if(result[0].length === 0)
          if (result[0][0] === '0x0000000000000000000000000000000000000000')
            notRecords = true
          setBuyTrades(parseList(result))
        })
        .catch(err => {
          console.log(err);
        });
    }
    const getTokenList = async () => {
      return await web3Utils.getTokentList()
        .then(list => {
          setTokenList(list)
          //namesById = list
          let tokenById = []
          let tokenAddressById = []
          let i = 0
          list.forEach(token => {
            tokenById[token.id] = token.name
            tokenAddressById[token.id] = token.address
            namesByAddress[token.address] = token.name
            i++
            if (list.length === i) {
              // setTokenNamesById(tokenById)
              // setTokenAddressById(tokenAddressById)
              setTokenNamesByAddress(namesByAddress)
              // setTokenToName(tokenById[4])
              // setTokenSwapName(tokenById[2])
            }
          })
        })
        .catch(e => {
          console.log(e)
        })
    }

    getTokenList()
      .then(async () => {
        getBuyTrades()
        onFilterChange(await web3Utils.getSpsTokenAddress(), await web3Utils.getUSDTTokenAddress()); // SPS vs USDT
      })
  }, []);

  return (
    <>
      <div className="banner-P2P"><h1>P2P</h1><h2>Compra y vende Sparklife</h2></div>

      <OptionsBar tittleBar="Vender Sparklife" tokenList={tokenList} onFilterChange={onFilterChange} />
      {isLoading === true ?
        <div style={{ left: "45%", position: "absolute" }}>

          <Puff
            height="30"
            width="30"
            radisu={1}
            color="#f8359e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />

        </div> : ""}
      {buyTradesFilters.length === 0 ? <h1 style={{ color: "gray", textAlign: "center" }}>Sin Registros</h1> : ""}
      {buyTradesFilters.map((trade, index) => {
        return (
          <Offers
            key={index}
            option="Reservar y Vender"
            typeTrade={trade.typetrade}
            coin={trade.coin}
            tokenNameByAddress={tokenNamesByAddress}
            trade={trade}
            currency={trade.currency}
            city={trade.city}
            id={trade.id}
            pricetrade={trade.pricetrade}
            pricecop={trade.pricecop}
            seller={trade.seller}
            swBuyOrSell="1"
            buy_sell="/sellConfirm/"
          />
        );
      })
      }
    </>
  );
};

export default SellSps;
