import React, { useState } from "react";
import "../styles/Banks.css";
import { Puff } from 'react-loader-spinner'
import axios from 'axios'
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next';

const Banks = ({ name, account, id, parentCallback }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);

  // Evento comprar/vender
  const bankButtonEvent = async (id) => {

    swal({
      title: `¡${t("confirm")}!`,
      text: "¿Realmente desea usar esta cuenta de Banco?",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    })
      .then(result => {
        if (result) {
          setLoading(true)
          axios.put('https://localcoin.digital:8080/api/v1/user/' + window.ethereum.selectedAddress + '/selectbank', {
            numberAccount: account,
            bank: name
          })
            .then(() => {
              setLoading(false)
              swal("¡Buen trabajo!", "Banco asignado, acepta para continuar.", "success")
            })
            .catch(err => {
              console.log(err)
              swal("¡Ouch!", "No se pudo asignar el banco a su cuenta de P2P, intente nuevamente.", "warning")
              setLoading(false)
            })
        }
      })
  }

  const deleteBank = async (id) => {

    swal({
      title: t("q.are_you_sure"),
      text: t("q.delete_bank"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {

        if (willDelete) {
          setLoadingDelete(true)
          axios.delete('https://localcoin.digital:8080/api/v1/user/' + id + '/bank?wown=' + window.ethereum.selectedAddress)
            .then(() => {
              setLoadingDelete(false)
              swal(t("r.yes_bank_deleted"), {
                icon: "success",
              })
              parentCallback()
            })
            .catch(err => {
              console.log(err)
              swal("¡Ouch!", t("r.no_bank_deleted") + ". Info: " + err.response.data.msg, "warning")
              setLoadingDelete(false)
            })
        }
      })
  }

  return (
    <>
      <div id="bankCard" className="col-12 col-sm-4">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title"> {name}</h4>
            <h6 className="card-subtitle">{t("number")}: {account}</h6>
            <div className="card-footer">
              {/* <button className="btn btn-secondary" onClick={
                () => bankButtonEvent(id)
              }>
                {isLoading === false ? "Seleccionar" : ""}
                {isLoading === true ?
                  <Puff
                    height="30"
                    width="30"
                    radisu={1}
                    color="#f8359e"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    style="display: block !important;" />
                  : ""}
              </button> */}
              <button className="btn btn-secondary" onClick={
                () => deleteBank(id)
              }>
                {isLoadingDelete === false ? t("eliminate") : ""}
                {isLoadingDelete === true ?
                  <Puff
                    height="30"
                    width="30"
                    radisu={1}
                    color="#f8359e"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    style="display: block !important;" />
                  : ""}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banks;
