import React from "react";
import "../styles/HomePageCard.css"

const HomePageCard = ({ name, img, link, navigate }) => {
  return (
    <>
      <div className="container">
        <a className="row home-card" onClick={function () {
          navigate(link)
        }} style={{ "cursor": "pointer" }}>
          <h4 className="col-7">{name}</h4>
          <img className="col-5" src={img} alt="" />
        </a>
      </div>
    </>
  );
};

export default HomePageCard;
