import React, { useState, useEffect } from "react";
import "../styles/UserData.css";
import { Puff } from 'react-loader-spinner'
import axios from 'axios'
import WAValidator from 'wallet-address-validator'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Copy } from 'react-feather'
import web3Utils from '../Utils/web-utils'
import { useTranslation } from 'react-i18next';

const UserData = ({ userWallet }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const getUserData = async () => {
      if (!userWallet)
        userWallet = window.ethereum.selectedAddress
      axios.defaults.headers['x-access-token'] = ''
      axios.get('https://localcoin.digital:8080/api/v1/user/' + userWallet + '/wallet')
        .then((result) => {
          setUserData(result.data.user)
          // setBuyerBtcAddress(result.data.user.btcAddress)
          // setBuyerBtcAddressSaved(result.data.user.btcAddress?true:false)
        })
        .catch(err => {
          console.log(err)
        })
    }
    getUserData();
  }, [])

  var updateBuyerDB = async () => {
    return false
    // return new Promise((resolve, reject) => {
    //   axios.defaults.headers['x-access-token'] = ''
    //   axios.patch('https://localcoin.digital:8080/api/v1/user/' + userWallet, {
    //     btcAddress: buyerBtcAddress
    //   })
    //   .then(() => {
    //       resolve()
    //   })
    //   .catch(err => {
    //       console.log(err)
    //       reject(err)
    //   })
    // })
  }

  // Evento comprar/vender
  const bankButtonEvent = async () => {
    // if(buyerBtcAddress && !buyerBtcAddressSaved) {
    alert('Esta opción no esta habilitada aún')
    return false
    // if(!0) {
    //   setLoading(true)
    //   updateBuyerDB()
    //   .then(() => {
    //     alert("La información se ha enviado correctamente, por lo pronto recibirá sus BTCS (Bitcoin de Sparklife) a su dirección BEP20.")
    //     // setBuyerBtcAddressSaved(true)
    //     setLoading(false)
    //   })
    //   .catch(() => {
    //     alert("Can`t save successful, please try again")
    //     setLoading(false)
    //   })
    // }
    // else {
    //   alert('Esta opcion no esta habilitada aún')
    // }
    //}
  }

  return (
    <>
      <p>
        {t("name")}:<b>{userData.name}</b><br />
        {t("email")}:<b>{userData.email}</b><br />
        {t("cellphone")}:<b>{userData.phone}</b><br />
        {/* Banco:<b>{userData.bank}</b><br />
        Cuenta #:<b>{userData.numberAccount}</b> */}
      </p>
      <button className="UserData-button" onClick={
        () => bankButtonEvent()
      }>
        {isLoading === false ? t("update_data") : ""}
        {isLoading === true ?

          <Puff
            height="30"
            width="30"
            radisu={1}
            color="white"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            style="display: block !important;" />

          : ""}
      </button>
    </>
  );
};

export default UserData;
