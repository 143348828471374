import { React, useState, useEffect } from "react";
import "../../styles/Profile.css";
import Lists from "../Lists";
import AddAgentEscrower from './AddAgentEscrower'
import AddBankAccounts from './AddBankAccount'
import AddChaturbateAccounts from "./AddChaturbateAccount";
import UserData from "../UserData";
import BtcDeposit from "../BtcDeposit";
import web3Utils from '../../Utils/web-utils'
import UserFundsList from "../UserFundsList";
import swal from 'sweetalert'
import axios from 'axios'
import { Copy } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';


const Profile = () => {
  const { t } = useTranslation();
  const [viewOwner, setViewOwner] = useState(false)
  const [feedBackRate, setFeedBackRate] = useState(0)
  const [walletAddress, setWalletAddress] = useState((window.ethereum.selectedAddress) ? window.ethereum.selectedAddress : "")
  const [appendLink, setAppendLink] = useState("")

  async function WithdrawFunds() {

    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      txNetwork = p2pContract.methods
        .WithdrawFunds()
        .send({ feeLimit: 500_000_000 })
    else
      txNetwork = p2pContract.methods
        .WithdrawFunds()
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Pending transaction... please wait")
        })
        .on('error', (err) => {
          console.log(err);
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt);
        swal(t("r.good_job"), t("r.transaction_approve_wallet"), "success")
      })
      .catch(e => {
        console.log(e)
        swal(t("r.some_went_wrong"), t("r.transaction_canceled"), "warning")
      })
  }

  useEffect(() => {

    async function isOwner() {
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      //setViewOwner(true)
      //return true
      return await p2pContract.methods
        .owner()
        .call()
        .then(result => {
          if (result.toLowerCase() === window.ethereum.selectedAddress.toLowerCase())
            setViewOwner(true)
        })
        .catch(err => {
          console.log(err);
        });
    }

    async function getRate() {
      axios.defaults.headers['x-access-token'] = ''
      axios.get('https://localcoin.digital:8080/api/v1/user/' + window.ethereum.selectedAddress + '/feedback/rate')
        .then((result) => {
          setFeedBackRate(result.data.rate)
        })
        .catch(err => {
          console.log(err)
        })
    }

    if (!window.ethereum.selectedAddress) {
      let data = web3Utils.getUserSesionData()
      if (data.account !== null)
        window.ethereum.selectedAddress = data.account
      setWalletAddress(data.account)
    }
    else
      setWalletAddress(window.ethereum.selectedAddress)


    setAppendLink(web3Utils.getUserSesionData().provider !== 3 ? "metamask.app.link/dapp/" : "")
    getRate()
    isOwner()
  }, []);

  return (
    <>
      <div className="banner-P2P"><h1>P2P</h1><h2>{t("profile")}</h2></div>
      <div className="container">
        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="data-tab" data-bs-toggle="tab" data-bs-target="#data-tab-pane" type="button" role="tab" aria-controls="data-tab-pane" aria-selected="true">{t("my_data")}</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="banco-tab" data-bs-toggle="tab" data-bs-target="#banco-tab-pane" type="button" role="tab" aria-controls="banco-tab-pane" aria-selected="false">{t("bank")}</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="chaturbate-tab" data-bs-toggle="tab" data-bs-target="#chaturbate-tab-pane" type="button" role="tab" aria-controls="chaturbate-tab-pane" aria-selected="false">{t("chaturbate")}</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="fondop2p-tab" data-bs-toggle="tab" data-bs-target="#fondop2p-tab-pane" type="button" role="tab" aria-controls="fondop2p-tab-pane" aria-selected="false">{t("p2p_funds")}</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="fondo-tab" data-bs-toggle="tab" data-bs-target="#fondo-tab-pane" type="button" role="tab" aria-controls="fondo-tab-pane" aria-selected="false">{t("btc_funds")}</button>
          </li>
          {viewOwner ?
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="admin-tab" data-bs-toggle="tab" data-bs-target="#admin-tab-pane" type="button" role="tab" aria-controls="admin-tab-pane" aria-selected="false">Admin</button>
            </li>
            : ""}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="data-tab-pane" role="tabpanel" aria-labelledby="data-tab" tabindex="0">
            <div className="data-tab-content container">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <h1>{t("my_data")}</h1>
                  <UserData userWallet={walletAddress} />
                  <br />{t("copy_link_refer")}
                  <CopyToClipboard text={"https://" + appendLink + "localcoin.digital?ref=" + window.ethereum.selectedAddress}
                    onCopy={() => window.alert("Link copiado!")}>
                    <span> <Copy /></span>
                  </CopyToClipboard>
                </div>
                <hr className="d-block d-sm-none"></hr>
                <div className="col-12 col-sm-6">
                  <h1>{t("rating")}</h1>
                  <Lists rate={feedBackRate} userWallet={walletAddress} />
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="banco-tab-pane" role="tabpanel" aria-labelledby="banco-tab" tabindex="0">
            <div className="banco-tab-content container">
              <h1>{t("bank_accounts")}</h1>
              <div className="Cards-container-top">
                <AddBankAccounts />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="chaturbate-tab-pane" role="tabpanel" aria-labelledby="chaturbate-tab" tabindex="0">
            <div className="chaturbate-tab-content container">
              <h1>{t("chaturbate_accounts")}</h1>
              <div className="Cards-container-top">
                <AddChaturbateAccounts />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="fondop2p-tab-pane" role="tabpanel" aria-labelledby="fondop2p-tab" tabindex="0">
            <div className="fondop2p-tab-content container text-center">
              <h1>{t("p2p_funds")}</h1>
              <UserFundsList />
              <button onClick={() => WithdrawFunds()} className="btn btn-secondary">{t("request_withdrawal")}</button>
            </div>
          </div>
          <div className="tab-pane fade" id="fondo-tab-pane" role="tabpanel" aria-labelledby="fondo-tab" tabindex="0">
            <div className="fondo-tab-content container">
              <h1>{t("deposits_withdrawals_btc")}</h1>
              <BtcDeposit userWallet={walletAddress} viewowner={viewOwner} />
            </div>
          </div>
          {viewOwner ?
            <div className="tab-pane fade" id="admin-tab-pane" role="tabpanel" aria-labelledby="admin-tab" tabindex="0">
              <div className="admin-tab-content container">
                <h1>Admin Tools</h1>
                <div className="">
                  <AddAgentEscrower />
                </div>
              </div>
            </div>
            : ""}
        </div>
      </div>
    </>
  );
};

export default Profile;
