import React, { useState, useEffect } from "react";
import "../../styles/AddBuyer.css";
import { Puff } from  'react-loader-spinner'
import axios from "axios";
import web3Utils from '../../Utils/web-utils'

const AddBuyer = () => {
  const [isLoading, setLoading] = useState(false)
  const [buyerAddress, setBuyerAddress] = useState("")
  const [buyerEmail, setBuyerEmail] = useState("")
  const [buyerName, setBuyerName] = useState("")
  const [buyerCedula, setBuyerCedula] = useState("")
  const [buyerBank, setBuyerBank] = useState("")
  const [buyerBtcAddress, setBuyerBtcAddress] = useState("")
  const [buyerNumberAccount, setBuyerNumberAccount] = useState("")
  const [buyerPassword, setBuyerPassword] = useState("")
  const [buyerPhone, setBuyerPhone] = useState("")
  const _referralAddress = localStorage.getItem("ref")
  
  var addBuyerDB = async (e) => {
    return new Promise((resolve, reject) => {
      axios.defaults.headers['x-access-token'] = ''
      axios.post('https://localcoin.digital:8080/api/v1/user', {
        wallet: buyerAddress,
        email: buyerEmail,
        phone: buyerPhone,
        name: buyerName,
        cedula: '',
        bank: buyerBank,
        numberAccount: buyerNumberAccount,
        password: buyerPassword,
        type: 0,
        referral: _referralAddress,
        btcAddress: buyerBtcAddress
      })
      .then(() => {
          resolve()
      })
      .catch(err => {
          console.log(err)
          reject(err)
      })
    })
  }

  var validateBuyer = (e) => { 
    if(
      //buyerAddress !== "" &&
      buyerEmail !== "" &&
      buyerPhone !== "" &&
      buyerBank !== "" &&
      // buyerCedula !== "" &&
      buyerName !== "" &&
      buyerNumberAccount !== ""/* &&
      buyerPassword !== ""*/
    ) {
      return true
    }
    else
      return false
  }

  /**
   * Agregar un nuevo vendedor
   * @param {*} e 
   */
  var addBuyer = async (e) => {

    if(!validateBuyer()) {
      alert("Por favor complete todos los campos")
      setLoading(false)
      return false
    }
    if(window.confirm("Desea registrarse ahora?")) {
        // Agregar en DB
        addBuyerDB()
        .then(async () => {
          setBuyerAddress("")
          setBuyerBank("")
          setBuyerCedula("")
          setBuyerEmail("")
          setBuyerName("")
          setBuyerNumberAccount("")
          //setBuyerPassword("")
          setBuyerPhone("")

          // Realizar transaccion para agregar el referido
          addReferral()
          .then(() => {
            window.alert("Has sido agregado correctamente, aceptar para continuar...")
            localStorage.removeItem("ref") // quitar el referido una vez sea agregado
            window.location.reload()
            setLoading(false)
          })
          .catch(err => {
            window.alert("No se asignó un referido para su cuenta. Verifique en el perfil de su cuenta.")
            window.location.reload()
          })
        })
        .catch((e) => {
          console.log(e)
          alert("Ya existe un usuario con la misma direccion (wallet)")
          setLoading(false)
        })
    }
    else
      setLoading(false)
  }

  /**
   * Asignar un referido al p2p
   * @returns 
   */
  var addReferral = () => {
    return new Promise(async (resolve, reject) => {

      debugger
      if(!_referralAddress){
        resolve()
        return
      }

      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PReferralContractAddress(), web3Utils.referralAbi)
      p2pContract.methods
        .setUserReferrer(_referralAddress)
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('error', (err) => {
          console.log(err)
          reject()
        })
        .then(receipt => {
          console.log(receipt)
          resolve()
        })
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    return addBuyer(e)
  }

  useEffect(() => { 
    // debugger
    setInterval(() => {
      // determinar el estado de la coneccion con la wallet y cargar por defecto los datos
      let data = web3Utils.getUserSesionData()
      if(data!=null) {
        if(data.account !== null)
          setBuyerAddress(data.account)
        else
          setBuyerAddress(window.ethereum.selectedAddress)
      }
    }, 1000)

  }, [])
  
  return (
    <>
      <div className="AddBuyer-Main-Container" style={{"color": "wheat", "border":"solid 1px black", "width": "96%", "textAlign": "center"}}>
        <h3>COMPLETA TU PERFIL</h3>
        <div className="Form-Container" style={{backgroundColor:"transparent"}}>
          <form onSubmit={handleSubmit} className="Form-Container" style={{textAlign: "center", maxWidth:"100% !important"}}>
            <label>Address</label>
            <div className="Form-Input-Container-Buyer">
              <input
                value={buyerAddress}
                disabled="disabled"
                className="Form-Input-Buyer"
                style={{"border":"solid 1px black", width: "90%", "color": "#e9970a"}}
                type="text"
              />
            </div>
            <label>Email</label>
            <div className="Form-Input-Container-Buyer">
              <input
                value={buyerEmail}
                onChange={(e) => setBuyerEmail(e.target.value)}
                className="Form-Input-Buyer"
                style={{"border":"solid 1px black", width: "90%"}}
                type="email"
              />
            </div>
            <label>Celular</label>
            <div className="Form-Input-Container-Buyer">
              <input
                value={buyerPhone}
                onChange={(e) => setBuyerPhone(e.target.value)}
                className="Form-Input-Buyer"
                style={{"border":"solid 1px black", width: "90%"}}
                type="number"
                placeholder="Ejm. 573016516012"
              />
            </div>
            <label>Nombre</label>
            <div className="Form-Input-Container-Buyer">
              <input
                value={buyerName}
                onChange={(e) => setBuyerName(e.target.value)}
                className="Form-Input-Buyer"
                style={{"border":"solid 1px black", width: "90%"}}
                type="text"
              />
            </div>
            <br/>
            <fieldset>
              <legend>Informaci&oacute;n de su Banco (Puede cambiarlo despu&eacute;s)</legend>              
              <label>Nombre del banco</label>
              <div className="Form-Input-Container-Buyer">
                <input
                  value={buyerBank}
                  onChange={(e) => setBuyerBank(e.target.value)}
                  className="Form-Input-Buyer"
                  style={{"border":"solid 1px black", width: "90%"}}
                  type="text"
                />
              </div>
              <label>Número de cuenta</label>
              <div className="Form-Input-Container-Buyer">
                <input
                  value={buyerNumberAccount}
                  onChange={(e) => setBuyerNumberAccount(e.target.value)}
                  className="Form-Input-Buyer"
                  style={{"border":"solid 1px black", width: "90%"}}
                  type="text"
                />
              </div>
            </fieldset>
            <br/>
            <fieldset>
              <legend>Informaci&oacute;n de su cuenta Bitcoin (BTC)</legend>
              <label>Por favor ingrese su direcci&oacute;n <b>BTC</b> para confirmar sus depositos</label>
              <p>Diligencie esta opcion si desea cambiar <b>BTC</b> a <b>BTCS</b> para operar en el P2P</p>
              <div className="Form-Input-Container-Buyer">
                <input
                  value={buyerBtcAddress}
                  onChange={(e) => setBuyerBtcAddress(e.target.value)}
                  className="Form-Input-Buyer"
                  style={{"border":"solid 1px black", width: "90%"}}
                  type="text"
                />
              </div>
            </fieldset>
            
            {!isLoading?
              <button className="Form-btn-buyer" style={{"width": "100px"}}>Agregar</button>
            :""}
            {isLoading===true?
              <div style={{margin: "20px 45%"}}>
                <Puff
                  height="30"
                  width="30"
                  radisu={1}
                  color="#f8359e"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
              :""}
          </form>
        </div>
      </div>
    </>
  );
};

export default AddBuyer;
