const p2pAbi = [
	{
		"constant": false,
		"inputs": [
			{
				"name": "SellerAddr",
				"type": "address"
			},
			{
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "ApplyOffertToEscrow",
		"outputs": [
			{
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "cancelOrder",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "ID",
				"type": "uint256"
			},
			{
				"name": "Decision",
				"type": "uint256"
			}
		],
		"name": "escrowDecision",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "SellerAddr",
				"type": "address"
			},
			{
				"name": "escrowId",
				"type": "uint256"
			}
		],
		"name": "EscrowEscalation",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "SellerAddr",
				"type": "address"
			},
			{
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "fundRelease",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [],
		"name": "getTokenList",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "weiAmmount",
				"type": "uint256"
			},
			{
				"name": "escrowAddress",
				"type": "address"
			},
			{
				"name": "notes",
				"type": "bytes32"
			},
			{
				"name": "sellOrBuy",
				"type": "uint256"
			},
			{
				"name": "price",
				"type": "uint256"
			},
			{
				"name": "tokenSwapId",
				"type": "uint256"
			},
			{
				"name": "_tokenToId",
				"type": "uint256"
			},
			{
				"name": "weiAmmountSwap",
				"type": "uint256"
			}
		],
		"name": "newEscrowSellOrBuy",
		"outputs": [
			{
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "SellerAddr",
				"type": "address"
			},
			{
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "offertEscrow",
		"outputs": [
			{
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "removeOfferToEscrow",
		"outputs": [
			{
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_sparkReferral",
				"type": "address"
			},
			{
				"name": "_sparkTokenList",
				"type": "address"
			},
			{
				"name": "_sparkSellerScrower",
				"type": "address"
			}
		],
		"name": "updateContract",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "newAddress",
				"type": "address"
			}
		],
		"name": "updateDevWallet",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "updateOwner",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "newFee",
				"type": "uint256"
			}
		],
		"name": "updateP2PFee",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "ID",
				"type": "uint256"
			},
			{
				"name": "_price",
				"type": "uint256"
			}
		],
		"name": "updatePriceScrow",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [],
		"name": "WithdrawFunds",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"name": "_sparkReferral",
				"type": "address"
			},
			{
				"name": "_sparkTokenList",
				"type": "address"
			},
			{
				"name": "_sparkSellerScrower",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"payable": true,
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"name": "SellerAddr",
				"type": "address"
			}
		],
		"name": "EnewOffertSell",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"name": "buyerAddress",
				"type": "address"
			}
		],
		"name": "EBought",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"name": "buyerAddress",
				"type": "address"
			}
		],
		"name": "EoffertEscrow",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"name": "withdrawToAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Ewithdraw",
		"type": "event"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "address"
			},
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "buyerEscrowDatabase",
		"outputs": [
			{
				"name": "buyer",
				"type": "address"
			},
			{
				"name": "seller",
				"type": "address"
			},
			{
				"name": "escrowId",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "SellerAddr",
				"type": "address"
			},
			{
				"name": "nounce",
				"type": "uint256"
			}
		],
		"name": "checkStatus",
		"outputs": [
			{
				"name": "",
				"type": "bytes32"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "address"
			},
			{
				"name": "",
				"type": "address"
			}
		],
		"name": "Funds",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "buyer",
				"type": "address"
			},
			{
				"name": "minLoad",
				"type": "uint256"
			},
			{
				"name": "maxLoad",
				"type": "uint256"
			}
		],
		"name": "getBuyerTransaction",
		"outputs": [
			{
				"name": "",
				"type": "address[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "bytes32[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "address[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "SellerAddr",
				"type": "address"
			},
			{
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "getReserveTimeOfTransaction",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "action",
				"type": "uint256"
			},
			{
				"name": "numToLoad",
				"type": "uint256"
			}
		],
		"name": "getSellerOrdersToApproveOrActive",
		"outputs": [
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "bytes32[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "address[]"
			},
			{
				"name": "",
				"type": "address[]"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "sellerAddress",
				"type": "address"
			}
		],
		"name": "getSellerTransactionLength",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "SellerAddr",
				"type": "address"
			},
			{
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "getSpecificSellOrBuyTransaction",
		"outputs": [
			{
				"name": "",
				"type": "address"
			},
			{
				"name": "",
				"type": "address"
			},
			{
				"name": "",
				"type": "uint256"
			},
			{
				"name": "",
				"type": "bytes32"
			},
			{
				"name": "",
				"type": "uint256"
			},
			{
				"name": "",
				"type": "bytes32"
			},
			{
				"name": "",
				"type": "uint256"
			},
			{
				"name": "",
				"type": "address"
			},
			{
				"name": "",
				"type": "address"
			},
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "numToLoad",
				"type": "uint256"
			},
			{
				"name": "action",
				"type": "uint256"
			}
		],
		"name": "marketBuyOrSellOrders",
		"outputs": [
			{
				"name": "",
				"type": "address[]"
			},
			{
				"name": "",
				"type": "address[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "bytes32[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			},
			{
				"name": "",
				"type": "address[]"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "usrAddress",
				"type": "address"
			}
		],
		"name": "myFunds",
		"outputs": [
			{
				"name": "",
				"type": "address[]"
			},
			{
				"name": "",
				"type": "uint256[]"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "p2pFee",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "address"
			},
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "scrowDB",
		"outputs": [
			{
				"name": "escrowId",
				"type": "uint256"
			},
			{
				"name": "escrow_intervention",
				"type": "bool"
			},
			{
				"name": "release_approval",
				"type": "bool"
			},
			{
				"name": "refund_approval",
				"type": "bool"
			},
			{
				"name": "notes",
				"type": "bytes32"
			},
			{
				"components": [
					{
						"name": "tokenId",
						"type": "address"
					},
					{
						"name": "price",
						"type": "uint256"
					},
					{
						"name": "amount",
						"type": "uint256"
					},
					{
						"name": "type_escrow",
						"type": "uint256"
					},
					{
						"name": "weiAmmount",
						"type": "uint256"
					},
					{
						"name": "weiAmmountSwap",
						"type": "uint256"
					},
					{
						"name": "buyer",
						"type": "address"
					},
					{
						"name": "seller",
						"type": "address"
					},
					{
						"name": "escrow_agent",
						"type": "address"
					},
					{
						"name": "escrow_fee",
						"type": "uint256"
					},
					{
						"name": "reserveOffertAddress",
						"type": "address"
					},
					{
						"name": "isTokenSwap",
						"type": "uint256"
					},
					{
						"name": "tokenSwapId",
						"type": "uint256"
					},
					{
						"name": "tokenToId",
						"type": "uint256"
					}
				],
				"name": "detail",
				"type": "tuple"
			},
			{
				"name": "created_date",
				"type": "uint256"
			},
			{
				"name": "reserve_date",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenList",
		"outputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	}
]

export default p2pAbi