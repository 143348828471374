import React, { useState } from "react";
import web3Utils from '../../Utils/web-utils'
import "../../styles/AddBuyer.css";
import { Puff } from 'react-loader-spinner'
import axios from "axios";

const AddAgentEscrower = () => {
  const [isLoading, setLoading] = useState(false);
  const [agentAddress, setAgentAddress] = useState("")
  const [agentFee, setAgentFee] = useState("")

  var validateAgent = (e) => {
    if (
      agentAddress !== "" &&
      agentFee !== ""
    ) {
      return true
    }
    else
      return false
  }

  /**
   * Agregar un nuevo vendedor
   * @param {*} e 
   */
  var addAgentEscrower = async (e) => {

    if (!validateAgent()) {
      alert("Por favor complete todos los campos")
      setLoading(false)
      return false
    }
    if (window.confirm("Desea agregar el nuevo Agente Árbitro?")) {
      if (window.web3.utils.isAddress(agentAddress)) {
        const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
        var txNetwork
        if (web3Utils.getUserSesionData().provider === 3)
          txNetwork = p2pContract.methods
            .addAgentEscrower(agentAddress, agentFee, 1)
            .send({ feeLimit: 500_000_000 })
        else
          txNetwork = p2pContract.methods
            .addAgentEscrower(agentAddress, agentFee, 1)
            .send({ value: '0', from: window.ethereum.selectedAddress })
            .on('transactionHash', hash => {
              console.log(hash)
            })
            .on('transactionHash', () => {
              console.log("Pending transaction... please wait")
            })
            .on('error', (err) => {
              console.log(err);
              setLoading(false)
            })

        return await txNetwork
          .then(receipt => {
            setLoading(false)
            setAgentAddress("")
            setAgentFee("")
            window.alert("Se ha agregado el nuevo Agente Árbitro correctamente");
          })
      }
      else {
        setLoading(false)
        alert("Ingrese una dirección de billetera valida.")
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    return addAgentEscrower(e)
  };

  return (
    <>
      <div className="container text-center">
        <h3>Agregar nuevo agente árbitro</h3>
        <form onSubmit={handleSubmit} >
          <div className="row">
            <label className="col-12 col-sm-4 text-md-end" for="agentAddress">Dirección</label>
            <div className="col-12 col-sm-8 col-lg-4">
              <input
                id="agentAddress"
                value={agentAddress}
                onChange={(e) => setAgentAddress(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <label className="col-12 col-sm-4 text-md-end" for="agentFee">Fee (1% - 10% max)</label>
            <div className="col-12 col-sm-8 col-lg-4">
              <input
                id="agentFee"
                value={agentFee}
                onChange={(e) => setAgentFee(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
          </div>
          <div className="text-center">
            {!isLoading ?
              <button className="btn btn-secondary">Agregar</button>
              : ""}
            {isLoading === true ?
              <Puff
                height="30"
                width="30"
                radisu={1}
                color="#f8359e"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              : ""}
          </div>
        </form>
      </div>
    </>
  );
};

export default AddAgentEscrower;
