import React, { useState, useEffect } from "react";
import web3Utils from '../Utils/web-utils'
import "../styles/TimeReserveTransaction.css";
import { Puff } from 'react-loader-spinner'
/*
import axios from 'axios'
import Talk from "talkjs"
import swal from 'sweetalert'
import feedbacklogo from "../assets/userfeedback.png"
import loading from "../assets/loading.gif"*/


const TimeReserveTransaction = ({ seller, id }) => {

  const [notRecords, setNotRecords] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [timeReserveTransaction, setTimeReserveTransaction] = useState(0)
  const [timeResult, setTimeResult] = useState(0)


  function updateDownTime(_result) {
    let nowDate = new Date()
    let diff = 0
    let txDate = new Date((new Date(_result * 1000)).getTime() + (30 * 60000))
    if (nowDate.getTime() < txDate.getTime())
      diff = Math.floor(Math.abs(nowDate - txDate) / 60000)
    setTimeResult(diff)

  }

  useEffect(() => {
    const getTransactionTime = async () => {
      setIsLoading(true)
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      return await p2pContract.methods
        .getReserveTimeOfTransaction(seller, id)
        .call()
        .then(result => {
          console.log(result);
          setIsLoading(false)
          if (result === '0' || result === undefined)
            setNotRecords(true)
          else {
            setNotRecords(false)
            setTimeReserveTransaction(result) // a minutos
            setInterval(() => { updateDownTime(result) }, 1000)
          }
        })
        .catch(err => {
          console.log(err);
          setNotRecords(true)
          setIsLoading(false)
        });
    }
    getTransactionTime()
  }, [])

  return (
    <>
      {isLoading === true ?
        <div style={{ left: "45%", position: "absolute" }}>
          <Puff
            height="30"
            width="30"
            radisu={1}
            color="#f8359e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> : ""}
      {notRecords ? <h1 style={{ textAlign: "center" }}>Sin Registros</h1> : ""}
      <div>
        <p>
          <span className="time-reserve">{timeResult}</span> minutos restantes
        </p>
      </div>
    </>
  );
};

export default TimeReserveTransaction;
