import React, { useState, useEffect } from "react";
import web3Utils from '../Utils/web-utils'
import "../styles/TimeReserveTransaction.css";
import { Puff } from 'react-loader-spinner'
import { Copy } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import coins from "../assets/monedas/index"
import { useTranslation } from 'react-i18next';

/*
import axios from 'axios'
import Talk from "talkjs"
import swal from 'sweetalert'
import feedbacklogo from "../assets/userfeedback.png"
import loading from "../assets/loading.gif"*/


const UserFundsList = () => {
  const { t } = useTranslation();
  const [notRecords, setNotRecords] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tokenFundsList, setTokenFundsList] = useState([])

  const copy = (value) => {
    navigator.clipboard.writeText(value)
    window.alert("Dirección copiada!")
  }

  useEffect(() => {

    const parseList = (listArray, tokenNames) => {
      let result = []
      let totalRows = listArray[0].length
  
      for(let i = 0; i < totalRows; i++){
        
          result[i] = {
            name: tokenNames[listArray[0][i]],
            // amount: (tokenNames[listArray[0][i]] != "BUSD")?listArray[1][i]/10**web3Utils.getDecimalsByAddress[listArray[0][i]]:0,
            amount: (listArray[1][i]/10**web3Utils.getDecimalsByAddress[listArray[0][i]]),
            address: listArray[0][i]
          }
      }

      return result
    }

    const getTokenFundsList = async (tokenNames) => {
      setIsLoading(true)
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi)
      return await p2pContract.methods
        .myFunds(window.ethereum.selectedAddress)
        .call()
        .then(result => {
          setIsLoading(false)
          if (result === '0' || result === undefined)
            setNotRecords(true)
          else {
            setNotRecords(false)
            setTokenFundsList(parseList(result, tokenNames))
          }
        })
        .catch(err => {
          console.log(err)
          setNotRecords(true)
          setIsLoading(false)
        });
    }
    web3Utils.getTokentList()
      .then(result => {
        let i = 0
        let namesByAddress = []
        result.forEach(token => {
          namesByAddress[token.address] = token.name
          i++
          if (result.length === i) {
            getTokenFundsList(namesByAddress)
          }
        })

      })

  }, [])

  return (
    <>
      {isLoading === true ?
        <div style={{ left: "45%", position: "absolute" }}>
          <Puff
            height="30"
            width="30"
            radisu={1}
            color="#f8359e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> : ""}
      {notRecords ? <h1 style={{ textAlign: "center" }}>{t("no_records")}</h1> : ""}
      <div className="container table-responsive">
        <table className="table table-primary table-striped table-hover">
          <thead>
            <tr key="1">
              <th>{t("tokencoin")}</th>
              <th>{t("balance")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              tokenFundsList.map((token, index) => {
                return (
                  <tr key={index}>
                    <td><img src={coins[index + 2]} /> {token.name}</td>
                    <td>{token.amount}</td>
                    <td>
                      <CopyToClipboard text={token.address}
                        onCopy={() => window.alert("Link copiado!")}>
                        <span><Copy size={15} /></span>
                      </CopyToClipboard>
                    </td>
                  </tr>
                );
              })}
          </tbody>
          {notRecords ? <tr><td colSpan={7}><h1 style={{ textAlign: "center" }}>{t("no_records")}</h1></td></tr> : ""}
        </table>
      </div>
    </>
  );
};

export default UserFundsList;
