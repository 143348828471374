import React, { useEffect, useState } from "react";
import "../../styles/BuyConfirm.css";
import BuySellConfirmCard from "../BuySellConfirmCard";
import { useParams } from "react-router-dom";
import web3Utils from '../../Utils/web-utils'
import axios from 'axios'
import { useTranslation } from 'react-i18next';

var namesByAddress = []
const BuySellConfirm = () => {
  const { t } = useTranslation();
  const { id, seller, swBuyOrSell } = useParams();
  const sellerAddress = seller.toLowerCase()

  // const [getId, setId] = useState(id)
  // const [sellerAddress, setSeller] = useState(seller.toLowerCase())
  const [getTrade, setTrade] = useState({})
  const [sellerData, setSelerData] = useState(id)
  // const [tokenList, setTokenList] = useState([])
  const [tokenNamesByAddress, setTokenNamesByAddress] = useState([])
  // const [feedBackRate, setFeedBackRate] = useState(0);

  const parseList = (response) => {
    try {
      let result = {}
      result["id"] = parseInt(response[4])
      result["seller"] = sellerAddress
      result["escrowerAgent"] = response[1]
      result["pricetrade"] = response[6] / (10 ** web3Utils.getDecimalsByAddress[response[8]])
      result["ammount"] = response[2] / (10 ** web3Utils.getDecimalsByAddress[response[7]])
      result["status_trade"] = web3Utils.toAscii(response[3]).replace(/\x00/g, "")
      result["extra_info_payment"] = web3Utils.toAscii(response[5]).replace(/\x00/g, "")
      result["typetrade"] = "Buy"
      result["coin"] = namesByAddress[response[7]]
      result["city"] = "a"
      result["country_id"] = 1
      result["currency"] = "USD"
      result["user_first"] = sellerAddress
      result["user_second"] = response[0] // buyer
      result["payment_method_id"] = 1
      result["created_at"] = "2022-04-28T17:38:52.347Z"
      result["updated_at"] = "2022-04-28T17:38:52.347Z"
      result["tokenTo"] = response[7]
      result["tokenSwap"] = response[8]
      result["tokenToName"] = namesByAddress[response[7]]
      result["tokenSwapName"] = namesByAddress[response[8]]

      return result
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const getSellerData = async () => {
    return new Promise((resolve, reject) => {
      axios.get('https://localcoin.digital:8080/api/v1/user/' + sellerAddress + '/wallet')
        .then(_sellerData => {
          setSelerData(_sellerData.data.user)
        })
    })
  }

  // const parseTokenList = (response) => {
  //   let result = []
  //   let totalRows = response[0].length
  //   for (let i = 0; i < totalRows; i++) {
  //     result[i] = {}
  //     result[i]["id"] = parseInt(response[0][i])
  //     result[i]["name"] = web3Utils.toAscii(response[2][i]).replace(/\x00/g, "")
  //     result[i]["address"] = response[1][i]
  //   }
  //   return result
  // }

  useEffect(() => {
    const getData = async () => {
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      return await p2pContract.methods
        .getSpecificSellOrBuyTransaction(sellerAddress, id)
        .call()
        .then(result => {
          setTrade(parseList(result))
          if (swBuyOrSell === '0')
            getSellerData()
        })
        .catch(err => {
          console.log(err)
        });
    }

    // async function getRate() {
    //   axios.defaults.headers['x-access-token'] = ''
    //   axios.get('https://localcoin.digital:8080/api/v1/user/' + sellerAddress + '/feedback/rate')
    //     .then((result) => {
    //       setFeedBackRate(result.data.rate)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // }

    web3Utils.getTokentList()
      .then(result => {
        // setTokenList(parseTokenList(result))
        let i = 0
        result.forEach(token => {
          namesByAddress[token.address] = token.name
          i++
          if (result.length === i) {
            setTokenNamesByAddress(namesByAddress)
            getData()
            // getRate()
          }
        })
      })
  }, [])

  return (
    <>

      <div className="banner-P2P"><h1>P2P</h1><h2>Confirmación de compra</h2></div>
      <div className="container">
        <BuySellConfirmCard tokenListByAddress={tokenNamesByAddress}
          back_to={swBuyOrSell === '0' ? "/BuySps" : "/SellSps"} complete={t("confirm_transaction")}
          idTrade={id} date={new Date().toString()} buy_sell={swBuyOrSell === '0' ? t("buy") : t("sell")}
          one_pridetrade={getTrade.pricetrade} trade_extra_info_payment={getTrade.extra_info_payment}
          seller={sellerAddress} trade={getTrade} swBuyOrSell={swBuyOrSell} sellerData={sellerData} />

        <h3><b>{t("steps_approve")} {swBuyOrSell === '0' ? t("buys") : t("sale")}</b></h3>
        <ol className="list-group list-group-numbered">
          <li className="list-group-item">{swBuyOrSell === '0' ? t("text.step_1_buys") : t("text.step_1_sale", { coin: getTrade.coin })}</li>
          <li className="list-group-item">{swBuyOrSell === '0' ? t("text.step_2_buys", { coin: getTrade.coin }) : t("text.step_2_sale", { coin: getTrade.coin })}</li>
          <li className="list-group-item">{swBuyOrSell === '0' ? t("text.step_3_buys", { coin: getTrade.coin }) : t("text.step_3_sale")}</li>
          <li className="list-group-item">{swBuyOrSell === '0' ? t("text.step_4_buys") : t("text.step_4_sale", { coin: getTrade.coin })}</li>
        </ol>
        <br />
      </div>
    </>
  );
};

export default BuySellConfirm;
