import React, { useState, useEffect } from "react";
import "../../styles/AddBuyer.css";
import { Puff } from 'react-loader-spinner'
import axios from "axios";
import Banks from "../Banks"
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next';

const AddChaturbateAccounts = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false)
    const [chaturbateName, setChaturbateName] = useState("")
    const [chaturbateAccount, setChaturbateAccount] = useState("")
    const [chaturbateList, setChaturbateList] = useState([])
    // const [isInternationalAccount, setIsInternationalAccount] = useState(false)
    // const [bankBen, setBankBen] = useState("")

    useEffect(() => {
        getChaturbateList();
    }, []);

    // var validateBank = (e) => {
    //     if (
    //         bankName !== "" &&
    //         bankAccount !== "" &&
    //         bankAccount.length >= 8
    //     ) {
    //         if (isInternationalAccount)
    //             if (bankBen !== "")
    //                 return true
    //             else
    //                 return false
    //         return true
    //     }

    //     return false
    // }

    /**
     * Agregar un nuevo vendedor
     * @param {*} e 
     */
    var addChaturbateAccount = async (e) => {

        // Validar datos
        // if (!validateBank()) {
        //     alert("Por favor complete todos los campos")
        //     setLoading(false)
        //     return false
        // }
        // TODO aún no guarda isInternational y ben
        axios.post('https://sparklifep2p.com:8080/api/v1/user/' + window.ethereum.selectedAddress + '/chaturbate', {
            name: chaturbateName,
            account: chaturbateAccount,
            // ben: isInternationalAccount ? bankBen : "",
        })
            .then(() => {
                setLoading(false)
                swal(t("r.good_job"), t("r.bank_created"), "success")
                setChaturbateAccount("")
                setChaturbateName("")
                // setIsInternationalAccount(false)
                // setBankBen("")
                // Actualizar el listado
                getChaturbateList()
            })
            .catch(err => {
                console.log(err)
                swal("¡Ouch!", t("r.bank_no_created"), "success")
                setLoading(false)
            })
    }

    // const handleCheckedInternational = (e) => {
    //     setIsInternationalAccount(e.target.checked)
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        return addChaturbateAccount(e)
    };

    const getChaturbateList = async () => {
        axios.defaults.headers['x-access-token'] = '' //dawd 
        axios.get('https://sparklifep2p.com:8080/api/v1/user/' + window.ethereum.selectedAddress + '/chaturbate')
            .then((result) => {
                setChaturbateList(result.data.banks)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <div className="container text-center">

                <h5>{t("new_chaturbate_account")}</h5>
                <div className="row ">
                    <div className="col-12">
                        <form onSubmit={handleSubmit}>
                            {/* <div className="row justify-content-center">
                <label className="col-12 col-sm-4 col-lg-3 text-sm-end" for="bankName">{t("name")}</label>
                <div className="col-12 col-sm-8 col-lg-4">
                  <input
                    id="bankName"
                    minLength={5}
                    maxLength={15}
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    className="form-control"
                    type="text"
                    oninput="this.value=this.value.replace(/[^A-Za-z\s]/g,'').slice(0,20);"
                    required
                  />
                </div>
              </div> */}
                            <div className="row justify-content-center">
                                <label className="col-12 col-sm-4 col-lg-3 text-sm-end" for="chaturbateAccount">{t("chaturbate_account")}</label>
                                <div className="col-12 col-sm-8 col-lg-4">
                                    <input
                                        id="chaturbateAccount"
                                        minLength={1}
                                        // maxLength={20}
                                        // min={0}
                                        value={chaturbateAccount}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 19) {
                                                setChaturbateAccount(e.target.value)
                                            }
                                        }
                                        }
                                        className="form-control"
                                        type="text"
                                        // title="Mínimo 9 caracteres"
                                        required
                                    />
                                </div>
                            </div>
                            {!isLoading ?
                                <button className="btn btn-secondary">{t("add")}</button>
                                : ""}
                            {isLoading === true ?
                                <div style={{ margin: "20px 45%" }}>
                                    <Puff
                                        height="30"
                                        width="30"
                                        radisu={1}
                                        color="#f8359e"
                                        ariaLabel="puff-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                                : ""}
                        </form>
                    </div>
                </div>
                <hr></hr>
                <h5>{t("my_chaturbates")}</h5>
                <div className="row">
                    {chaturbateList.map((bank, index) => {
                        return (
                            <Banks
                                key={index}
                                name={bank.name}
                                account={bank.account}
                                id={bank._id}
                                parentCallback={getChaturbateList}
                            />
                        );
                    })
                    }
                </div>
            </div>

        </>
    );
};

export default AddChaturbateAccounts;
