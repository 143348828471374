import React, { useState, useEffect } from "react";
import "../styles/UserData.css";
import { Puff } from 'react-loader-spinner'
import axios from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Copy } from 'react-feather'
import web3Utils from '../Utils/web-utils'
import WAValidator from 'wallet-address-validator'
import { useTranslation } from 'react-i18next';

const BtcDeposit = ({ userWallet, viewowner }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})
  const [buyerBtcAddress, setBuyerBtcAddress] = useState("")
  const [btcWithdrawAmount, setBtcWithdrawAmount] = useState(0)
  const [btcsBalance, setBtcsBalance] = useState(0)
  const [sellerBtcAddress, setSellerBtcAddress] = useState("bc1q0d0xr7kr90qwhency4d7rvfhlud2cj9uf76dlz")
  const [buyerBtcAddressSaved, setBuyerBtcAddressSaved] = useState(false)

  const [mintAddress, setMintAddress] = useState('')
  const [mintBtcHash, setMintBtcHash] = useState('')
  const [btcMintAmount, setBtcMintAmount] = useState(0)


  useEffect(() => {
    const getUserData = async () => {
      if (!userWallet)
        userWallet = window.ethereum.selectedAddress

      let _btcsB = await web3Utils.getBTCSBalance()
      setBtcWithdrawAmount(_btcsB)
      setBtcsBalance(_btcsB)

      axios.defaults.headers['x-access-token'] = ''
      axios.get('https://localcoin.digital:8080/api/v1/user/' + userWallet + '/wallet')
        .then((result) => {
          setUserData(result.data.user)
          setBuyerBtcAddress(result.data.user.btcAddress)
          setBuyerBtcAddressSaved(result.data.user.btcAddress ? true : false)
        })
        .catch(err => {
          console.log(err)
        })
    }
    getUserData()

  }, [])

  var addTokenBTBSToMetamask = async () => {
    try {
      if (web3Utils.getUserSesionData().provider !== 3) {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: "0x1870579e822935F68Ea3f440feF8fe90c73C4a5D",
              symbol: "BTCS",
              decimals: "18",
              image: "https://bcatoken.com/images/btcs.jpeg",
            },
          },
        })
        if (wasAdded) {
          console.log('Token Added to Metamask successful.');
        } else {
          console.log('Cant add token at this moment, please try again.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  var approveBtcs = async () => {
    return new Promise(async (resolve, reject) => {
      const btcsContract = await web3Utils.getContract(await web3Utils.getBTCSTokenAddress(), web3Utils.btcsAbi)
      return await btcsContract.methods.allowance(userWallet, await web3Utils.getP2PWithdrawContractAddress())
        .call()
        .then(async ammount => {
          if (web3Utils.toBN(ammount) > 0) {
            resolve()
          } else {
            if (window.confirm(t("q.auth_btc_permission"))) {
              return await btcsContract.methods.approve(await web3Utils.getP2PWithdrawContractAddress(), '100000000000000000000')
                .send({ from: window.ethereum.selectedAddress })
                .then(async () => {
                  alert(t("r.approved_balance"))
                  resolve()
                })
            }
            else {
              setLoading(false)
            }
          }
        })
        .catch(() => {
          reject()
        })
    })
  }

  var witdrawBtc = async () => {
    return new Promise(async (resolve, reject) => {
      return approveBtcs()
        .then(async () => {
          const BtcsAdminContract = await web3Utils.getContract(await web3Utils.getP2PWithdrawContractAddress(), web3Utils.p2pBtcsWithdrawAbi)
          return await BtcsAdminContract.methods.withdrawBtc(web3Utils.toWei(btcWithdrawAmount.toString()))
            .send({ from: userWallet })
            .then(async (receipt) => {
              return confirmWitdrawBtc(receipt.transactionHash)
            })
            .catch(() => {
              reject()
            })
        })
        .catch(() => {
          alert(t("r.no_approve_btcs"))
          setLoading(false)
        })
    })
  }

  var confirmWitdrawBtc = async (txHassh = '') => {
    return new Promise((resolve, reject) => {
      if (!txHassh || txHassh === '') {
        reject()
        return
      }
      axios.defaults.headers['x-access-token'] = ''
      return axios.post('https://localcoin.digital:8080/api/v1/user/' + userWallet + '/btcwithdraw', {
        btcAmount: btcWithdrawAmount,
        hassh: txHassh
      })
        .then(() => {
          alert(t("r.info_btc_account"))
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  }

  // Evento comprar/vender
  const bankButtonEventWithdraw = async () => {
    if (isLoading) return false
    if (btcWithdrawAmount > 0) {
      if (btcWithdrawAmount <= btcsBalance) {
        setLoading(true)
        witdrawBtc()
          .then(() => {
            alert(t("r.info_btc_account"))
            setLoading(false)
          })
          .catch(() => {
            alert(t("r.fail_withdrawal"))
            setLoading(false)
          })
      } else {
        alert(t("r.no_balance_btcs"))
        setLoading(false)
      }
    } else {
      alert(t("r.amount_withdraw"))
      setLoading(false)
    }
  }

  var updateBuyerDB = async () => {
    return new Promise((resolve, reject) => {
      axios.defaults.headers['x-access-token'] = ''
      axios.patch('https://localcoin.digital:8080/api/v1/user/' + userWallet, {
        btcAddress: buyerBtcAddress
      })
        .then(() => {
          resolve()
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  }

  const bankButtonEvent = async () => {
    if (isLoading) return false
    if (buyerBtcAddress && !buyerBtcAddressSaved) {
      if (WAValidator.validate(buyerBtcAddress)) {
        setLoading(true)
        updateBuyerDB()
          .then(() => {
            alert("La información se ha enviado correctamente.")
            setBuyerBtcAddressSaved(true)
            setLoading(false)
          })
          .catch(() => {
            alert("No se pudo guardar los datos, por favor intente nuevamente.")
            setLoading(false)
          })
      }
      else {
        alert("La dirección BTC es invalida.")
        setLoading(false)
      }
    }
  }

  const bankButtonEventMint = async () => {
    if (isLoading) return false
    if (btcMintAmount > 0 && mintAddress !== '' && mintBtcHash !== '') {
      if (WAValidator.validate(mintAddress, 'ETH')) {
        setLoading(true)
        mintBtcs()
          .then(() => {
            alert("Se ha realizado el Minteo con exito!.")
            setBtcMintAmount('0')
            setMintAddress('')
            setMintBtcHash('')
            setLoading(false)
          })
          .catch(() => {
            alert("No se pudo realizar el minteo, intentelo nuevamente.")
            setLoading(false)
          })
      }
      else {
        alert("La dirección BEP20 es invalida.")
        setLoading(false)
      }
    } else {
      alert('Por favor complete los campos para mintear BTCS.')
      setLoading(false)
    }
  }

  function mintBtcs() {
    return new Promise(async (resolve, reject) => {
      try {
        let bytes = web3Utils.fromAscii(mintBtcHash)
        if (bytes.length < 66)
          bytes = bytes + web3Utils.completeWithZero(bytes.length, 66)
        if (bytes === "") bytes = "0x0000000000000000000000000000000000000000000000000000000000000000"

        const BtcsAdminContract = await web3Utils.getContract(await web3Utils.getP2PWithdrawContractAddress(), web3Utils.p2pBtcsWithdrawAbi)
        return await BtcsAdminContract.methods.mintBtcs(mintAddress, bytes, web3Utils.toWei(btcMintAmount.toString()))
          .send({ from: userWallet })
          .then(async (receipt) => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      } catch (err) {
        console.log(err)
        alert('No se pudo completar la operacion de Minteo.')
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className="container">
        <fieldset>
          <legend>{t("deposit")}</legend>
          <p>{t("text.deposit_btc_btcs")}
          </p>
          <div className="row">
            <label className="col-12 col-sm-4 text-md-end" for="buyerBtcAddress"><b>{t("address")}</b> ({t("text.address_btc")})</label>
            <div className="col-12 col-sm-7">
              <input
                id="buyerBtcAddress"
                value={buyerBtcAddress}
                onChange={(e) => setBuyerBtcAddress(e.target.value)}
                className="form-control"
                type="text"
                disabled={buyerBtcAddressSaved ? true : false}
              />
            </div>
          </div>
          {buyerBtcAddress !== "" && buyerBtcAddressSaved ?
            <div className="row">
              <label className="col-12 col-sm-4 text-md-end" for="sellerBtcAddress"><b>{t("address_deposit")}</b></label>
              <div className="col-12 col-sm-7">
                <input
                  id="sellerBtcAddress"
                  value={sellerBtcAddress}
                  onChange={(e) => setSellerBtcAddress(e.target.value)}
                  className="form-control"
                  type="text"
                  disabled="true"
                />
              </div>
              <div className="col-12 col-sm-1">
                <CopyToClipboard text={"bc1qv8g8gw5qfgcldx5w4e2xyquknp56p89ka6c5k7"}
                  onCopy={() => window.alert("Dirección copiada correctamente.")}>
                  <span><Copy /></span>
                </CopyToClipboard>
              </div>
            </div> : ""}
          <div className="text-center">
            {!buyerBtcAddressSaved ?
              <button className="btn btn-secondary" onClick={
                () => bankButtonEvent()
              }>
                {isLoading === false ? "Guardar" : ""}
                {isLoading === true ?

                  <Puff
                    height="30"
                    width="30"
                    radisu={1}
                    color="white"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    style="display: block !important;" />

                  : ""}
              </button>
              : ''}
            <button className="btn btn-secondary" style={{ "marginLeft": "5px" }} onClick={
              () => addTokenBTBSToMetamask()
            }>
              {isLoading === false ? t("add_btcs_meta"): ""}
              {isLoading === true ?

                <Puff
                  height="30"
                  width="30"
                  radisu={1}
                  color="white"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  style="display: block !important;" />

                : ""}
            </button>
          </div>
        </fieldset>
        <hr></hr>
        <fieldset>
          <legend>{t("withdraw")}</legend>
          <p>{t("text.withdraw_btcs_btc")}</p>
          <div className="row">
            <label className="col-12 col-sm-4 text-md-end" for="btcWithdrawAmount"><b>{t("quantity")}</b></label>
            <div className="col-12 col-sm-8">
              <input
                id="btcWithdrawAmount"
                value={btcWithdrawAmount}
                onChange={(e) => setBtcWithdrawAmount(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
          </div>

          <div className="text-center">
            <button className="btn btn-secondary" onClick={
              () => bankButtonEventWithdraw()
            }>
              {isLoading === false ? t("apply_now") : ""}
              {isLoading === true ?

                <Puff
                  height="30"
                  width="30"
                  radisu={1}
                  color="white"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  style="display: block !important;" />

                : ""}
            </button>
          </div>
        </fieldset>
        <hr></hr>
        {userData.isOwner ?
          <fieldset>
            <legend>{t("mint_create_btcs")}</legend>
            <p>{t("text.convert_btc_btcs")}</p><br />
            <div className="row">
              <label className="col-12 col-sm-4 text-md-end" for="mintAddress"><b>{t("address")} (BEP20) ({t("text.address_btcs_out")})</b></label>
              <div className="col-12 col-sm-8">
                <input
                  id="mintAddress"
                  value={mintAddress}
                  onChange={(e) => setMintAddress(e.target.value)}
                  className="form-control"
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <label className="col-12 col-sm-4 text-md-end" for="mintBtcHash"><b>{t("text.hash_btc")}</b></label>
              <div className="col-12 col-sm-8">
                <input
                  id="mintBtcHash"
                  value={mintBtcHash}
                  onChange={(e) => setMintBtcHash(e.target.value)}
                  className="form-control"
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <label className="col-12 col-sm-4 text-md-end" for="btcMintAmount"><b>{t("quantity")} BTCS</b></label>
              <div className="col-12 col-sm-8">
                <input
                  id="btcMintAmount"
                  value={btcMintAmount}
                  onChange={(e) => setBtcMintAmount(e.target.value)}
                  className="form-control"
                  type="number"
                />
              </div>
            </div>
            <div className="text-center">
              <button className="btn btn-secondary" onClick={
                () => bankButtonEventMint()
              }>
                {isLoading === false ? t("create_send_btcs") : ""}
                {isLoading === true ?

                  <Puff
                    height="30"
                    width="30"
                    radisu={1}
                    color="white"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    style="display: block !important;" />

                  : ""}
              </button>
            </div>
          </fieldset>
          : ''}
      </div >
    </>
  );
};

export default BtcDeposit;
