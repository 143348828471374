import React, { useState, useEffect } from "react";
import web3Utils from '../Utils/web-utils'
import "../styles/Table.css";
import { Puff } from 'react-loader-spinner'
import Talk from "talkjs"
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next';

const Table = () => {
  const { t } = useTranslation();
  const [isLoadingDesicion, setLoadingDesicion] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [notRecords, setNotRecords] = useState(false)
  const parseList = (response) => {
    try {
      if(!response) return []
      let result = []
      let totalRows = response[0].length

      for (let i = 0; i < totalRows; i++) {
        if (response[1][i] !== "0") {
          result[i] = {}
          result[i]["id"] = parseInt(response[2][i])
          result[i]["buyer"] = response[1][i]
          result[i]["seller"] = response[0][i]
        }
      }
      return result
    } catch (err) {
      console.log(err)
      return []
    }
  }
  const [history, setHistory] = useState([]);
  var p2pContract

  async function escrowDecision(action, id, e) {
    //e.preventDefault();
    setLoadingDesicion(true)
    p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi)
    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      txNetwork = p2pContract.methods
        .escrowDecision(
          id,
          action
        )
        .send({ feeLimit: 500_000_000 })
    else
      txNetwork = p2pContract.methods
        .escrowDecision(
          id,
          action
        )
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Pending transaction... please wait")
        })
        .on('error', (err) => {
          console.log(err)
          setLoadingDesicion(false)
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt)
        getHistory()
        setLoadingDesicion(false)
        swal("¡Buen trabajo!", "La transacción se ha elevado a un árbitro para que se tome una decisión.", "success")
      })
      .catch(e => {
        console.log(e)
        setLoadingDesicion(false)
      })
  }

  const getHistory = async () => {
    const p2pContract2 = await web3Utils.getContract(await web3Utils.getP2PSellerScrowerContractAddress(), web3Utils.sellerScrowerAbi)
    return await p2pContract2.methods
      .getEscrowerTransactions(window.ethereum.selectedAddress)
      .call()
      .then(result => {
        setIsLoading(false)
        if (result[0][0] === '0x0000000000000000000000000000000000000000')
          setNotRecords(true)
        setHistory(parseList(result));
      })
      .catch(err => {
        setIsLoading(false)
        setNotRecords(true)
        console.log(err);
      });
  }

  const openChatEscrower = (sellerAddress, buyerAddress, txId, toSeller = false) => {

    const to = new Talk.User({
      id: window.ethereum.selectedAddress.toLowerCase(),
      name: window.ethereum.selectedAddress.substring(0, 5) + "..." + window.ethereum.selectedAddress.slice(-5),
      email: null,
      role: "sellers"
    })
    var addressTo = toSeller ? sellerAddress : buyerAddress
    const me = new Talk.User({
      id: addressTo.toLowerCase(),
      name: addressTo.substring(0, 5) + "..." + addressTo.slice(-5),
      email: null,
      role: "sellers"
    })

    var conversation = window.talkSession.getOrCreateConversation(
      Talk.oneOnOneId(me, to)
    )
    conversation.setParticipant(me)
    conversation.setParticipant(to)
    window.popupChat.select(conversation)
    window.popupChat.show()
  }

  useEffect(() => {
    const getHistory = async () => {

      const p2pContract2 = await web3Utils.getContract(await web3Utils.getP2PSellerScrowerContractAddress(), web3Utils.sellerScrowerAbi);

      return await p2pContract2.methods
        .getEscrowerTransactions(window.ethereum.selectedAddress)
        .call()
        .then(result => {
          setIsLoading(false)
          if (result[0][0] === '0x0000000000000000000000000000000000000000')
            setNotRecords(true)
          else
            setNotRecords(false)
          setHistory(parseList(result));
        })
        .catch(err => {
          setIsLoading(false)
          setNotRecords(true)
          console.log(err);
        });
    }
    getHistory();
  }, []);


  return (
    <>
      {isLoading === true ?
        <div style={{ left: "45%", position: "absolute" }}>
          <Puff
            height="30"
            width="30"
            radisu={1}
            color="#f8359e"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> : ""}
      <div className="container table-responsive">
        <table className="table table-primary table-striped table-hover">
          <caption>{t("intervention_requests")}</caption>
          <thead >
            <tr key="1">
              <th>{t("transaction")}</th>
              <th>{t("buyer")}</th>
              <th>{t("seller")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {history.map((history, index) => {
              return (
                <tr key={index}>
                  <td>{history.id}</td>
                  <td>{history.buyer.substring(0, 5) + "..." + history.buyer.slice(-5)}</td>
                  <td>{history.seller.substring(0, 5) + "..." + history.seller.slice(-5)}</td>
                  <td>
                    {!isLoadingDesicion ?
                      <span><button onClick={() => escrowDecision(0, history.id)} className="btn btn-secondary">{t("refund")}</button><br /></span>
                      : ""}
                    &nbsp;
                    {!isLoadingDesicion ?
                      <span><button onClick={() => escrowDecision(1, history.id)} className="btn btn-secondary">{t("approve")}</button><br /></span>
                      : ""}
                    {isLoadingDesicion === false ? <span><button onClick={() => openChatEscrower(history.seller, history.buyer, history.id)} className="action-button">{t("chat_buyer")}</button><br /></span>
                      : ""} &nbsp;
                    {isLoadingDesicion === false ? <span><button onClick={() => openChatEscrower(history.seller, history.buyer, history.id, true)} className="action-button">{t("chat_seller")}</button><br /></span>
                      : ""} &nbsp;
                    {isLoadingDesicion === true ?
                      <Puff
                        height="30"
                        width="30"
                        radisu={1}
                        color="#f8359e"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                      : ""}
                  </td>
                </tr>
              );
            })}
            {notRecords ? <tr><td colSpan={4}><h1 style={{ textAlign: "center" }}>{t("no_records")}</h1></td></tr> : ""}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
