import React, { useState, useEffect } from "react";
import "../../styles/History.css";
import Table from "../Table";
import TableSellOrders from "../TableSellOrders";
import TableSellOpenOrders from "../TableSellOpenOrders";
import TableEscrowerOrders from "../TableEscrowerOrders";
import TableHistoryOrders from "../TableSellHistoryOrders";
import web3Utils from '../../Utils/web-utils'
import { useTranslation } from 'react-i18next';

var namesByAddress = []
// Validar si es vendedor y mostrar otras vistas
const History = () => {
  const { t } = useTranslation();
  const [viewEscrower, setViewEscrower] = useState(false);
  const [tokenNamesByAddress, setTokenNamesByAddress] = useState([])

  useEffect(() => {

    async function isEscrower() {
      let add = await web3Utils.getP2PSellerScrowerContractAddress()
      const p2pContract = await web3Utils.getContract(add, web3Utils.sellerScrowerAbi)
      await p2pContract.methods
        .isAgentEscrower(window.ethereum.selectedAddress)
        .call()
        .then(result => {
          setViewEscrower(result)
        })
        .catch(err => {
          console.log(err)
          setViewEscrower(false)
        });
    }

    web3Utils.getTokentList()
      .then(result => {
        let i = 0
        result.forEach(token => {
          namesByAddress[token.address] = token.name
          i++
          if (result.length === i) {
            setTokenNamesByAddress(namesByAddress)
          }
        })
      })

    isEscrower()
  }, []);

  return (
    <>

      <div className="banner-P2P"><h1>P2P</h1><h2>{t("history_transaction")}</h2></div>

      {viewEscrower ? <div className="history-main-container">
        <div className="history-container">
          <h1 className="history-tittle">{t("intervention_requests")}</h1>
          <div className="history-table-container">
            <TableEscrowerOrders />
          </div>
        </div>
      </div> : ""}
      <div className="history-main-container">
        <div className="history-container">
          <h1 className="history-tittle">{t("history_purchasesales_offers")}</h1>
          <div className="history-table-container">
            <TableSellOrders />
          </div>
        </div>
      </div>
      <div className="history-main-container">
        <div className="history-container">
          <h1 className="history-tittle">{t("open_orders")}</h1>
          <div className="history-table-container">
            <TableSellOpenOrders />
          </div>
        </div>
      </div>
      <div className="history-main-container">
        <div className="history-container">
          <h1 className="history-tittle">{t("shopping_history")}</h1>
          <div className="history-table-container">
            <Table tokenListByAddress={tokenNamesByAddress} />
          </div>
        </div>
      </div>
      <div className="history-main-container">
        <div className="history-container">
          <h1 className="history-tittle">{t("sales_history")}</h1>
          <div className="history-table-container">
            <TableHistoryOrders />
          </div>
        </div>
      </div>
    </>
  );
};

export default History;