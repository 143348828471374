import React, { useState, useEffect } from "react";
import web3Utils from '../Utils/web-utils'
import "../styles/Table.css";
import { Puff } from 'react-loader-spinner'
import axios from 'axios'
import Talk from "talkjs"
import swal from 'sweetalert'
import feedbacklogo from "../assets/userfeedback.png"
import loading from "../assets/loading.gif"
import { useTranslation } from 'react-i18next';

var namesByAddress = []
const Table = () => {
  const { t } = useTranslation();
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [notRecords, setNotRecords] = useState(false)

  const parseList = (response) => {
    try {
      let result = []
      let totalRows = response[0].length

      for (let i = 0; i < totalRows; i++) {
        if (response[1][i] !== "0") {
          result[i] = {}
          result[i]["id"] = parseInt(response[3][i])
          result[i]["typetrade"] = parseInt(response[0][i]) ? "Sell" : "Buy"
          result[i]["buyer"] = response[4][i]
          result[i]["coin"] = namesByAddress[response[5][i]]
          result[i]["city"] = "dwad"
          result[i]["country_id"] = 1
          result[i]["currency"] = "USD"
          result[i]["user_first"] = ""
          result[i]["user_second"] = ""
          result[i]["payment_method_id"] = 1
          result[i]["extra_info_payment"] = "Nequi"
          result[i]["status_trade"] = web3Utils.toAscii(response[2][i]).replace(/\x00/g, "")
          result[i]["created_at"] = "2022-04-28T17:38:52.347Z"
          result[i]["updated_at"] = "2022-04-28T17:38:52.347Z"
          result[i]["pricetrade"] = 0
          result[i]["ammount"] = response[1][i] / 10 ** web3Utils.getDecimalsByAddress[response[5][i]]
          //result[i]["buyer"] = response[0][i]
          result[i]["buyOrSell"] = parseInt(response[0][i])
        }
      }
      return result
    } catch (err) {
      console.log(err)
      return []
    }
  }
  const [history, setHistory] = useState([]);

  async function fundRelease(seller, id, buyer, coin) {
    setIsLoadingAction(true)
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      txNetwork = p2pContract.methods
        .fundRelease(
          seller,
          id
        )
        .send({ feeLimit: 500_000_000 })
    else
      txNetwork = p2pContract.methods
        .fundRelease(
          seller,
          id
        )
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Pending transaction... please wait")
        })
        .on('error', (err) => {
          setIsLoadingAction(false)
          console.log(err);
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt)
        getHistory()

        swal({
          text: t("q.experience_transaction"),
          icon: feedbacklogo,
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: [t("bad"), t("good")],
          dangerMode: false,
        })
          .then(async goodsad => {

            swal({
              text: t("please_wait"),
              icon: loading,
              closeOnClickOutside: false,
              closeOnEsc: false,
              buttons: false
            })

            // Enviar calificacion
            axios.post('https://localcoin.digital:8080/api/v1/user/' + seller + '/feedback', {
              comment: t("no_comment"),
              goodSad: goodsad,
              orderId: id,
              walletTo: buyer
            })
              .catch(err => {
                console.log(err)
              })

            try {

              // Notificar al comprador que ya se aprobaron los fondos
              axios.post('https://localcoin.digital:8080/api/v1/notify/' + seller + '?f=' + buyer + '&t=4&id=' + id + '&ttn=' + coin + '&tsn=')
                .then(() => {
                  swal(t("r.good_job"), t("r.transaction_approve"), "success")
                  setIsLoadingAction(false)
                })
                .catch(err => {
                  console.log(err)
                  swal("¡Ouch!", t("r.transaction_approve_chat"), "warning")
                  setIsLoadingAction(false)
                })
            } catch (err) {
              setIsLoadingAction(false)
            }
          })
        return
      })
      .catch(e => {
        console.log(e)
        setIsLoadingAction(false)
        swal(t("r.some_went_wrong"), t("r.transaction_canceled"), "warning")
      })
  }

  async function EscrowAction(seller, id) {
    setIsLoadingAction(true)
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    var txNetwork
    if (web3Utils.getUserSesionData().provider === 3)
      txNetwork = p2pContract.methods
        .EscrowEscalation(
          seller,
          id
        )
        .send({ feeLimit: 500_000_000 })
    else
      txNetwork = p2pContract.methods
        .EscrowEscalation(
          seller,
          id
        )
        .send({ value: '0', from: window.ethereum.selectedAddress })
        .on('transactionHash', hash => {
          console.log(hash)
        })
        .on('transactionHash', () => {
          console.log("Transacción pendiente... por favor espere")
        })
        .on('error', (err) => {
          setIsLoadingAction(false)
          console.log(err);
        })

    return await txNetwork
      .then(receipt => {
        console.log(receipt);
        getHistory()
        swal(t("r.good_job"), t("r.transaction_arbitrator"), "success")
        setIsLoadingAction(false)
      })
      .catch(e => {
        console.log(e)
        setIsLoadingAction(false)
      })

  }

  // Notificar al comprador (vista seller) que el deposito esta realizado, en este caso si el seller esta comprando SPS (vendiendo COP)
  async function notifyPay(from, id, buyer, coin) {

    return new Promise(async (resolve, reject) => {
      setIsLoadingAction(true)
      // Notificar al comprador que ya se depositó
      axios.post('https://localcoin.digital:8080/api/v1/notify/' + from + '?f=' + buyer + '&t=0&id=' + id + '&ttn=' + coin + '&tsn=')
        .then(() => {
          localStorage.setItem("ID_" + id + "_" + from, "1")
          swal(t("r.good_job"), t("r.notify_deposit"), "success")
          setIsLoadingAction(false)
          resolve()
        })
        .catch(err => {
          console.log(err)
          swal("¡Ouch!", t("r.no_notify"), "success")
          setIsLoadingAction(false)
          resolve()
        })
      /*const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      return await p2pContract.methods
        .getSpecificSellOrBuyTransaction(from, id)
        .call()
        .then(_result => {
          console.log(_result);         

        })
        .catch(err => {
          console.log(err)
        })*/
    })

  }

  const getHistory = async () => {
    const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
    return await p2pContract.methods
      .getSellerOrdersToApproveOrActive(1, 20)
      .call({ from: window.ethereum.selectedAddress })
      .then(result => {
        setIsLoadingAction(false)
        if (result[0].length === 0 || result[1][0] === '0')
          setNotRecords(true)
        setHistory(parseList(result));
      })
      .catch(err => {
        console.log(err);
      });
  }


  /**
   * Abrir una nueva ventana de chat con el vendedor
   * @param {*} buyerAddress 
   * @param {*} txId 
   * @param {*} escrower 
   */
  const openChatWithBuyer = (buyerAddress, txId, escrower = false) => {

    const me = new Talk.User({
      id: window.ethereum.selectedAddress.toLowerCase(),
      name: "Chat con " + window.ethereum.selectedAddress.substring(0, 5) + "..." + window.ethereum.selectedAddress.slice(-5),
      email: null,
      role: "sellers"
    })

    var _AddressTo = escrower ? "0x47b833781a04ab68777f0c3881009b0103e3666a".toLowerCase() : buyerAddress
    const to = new Talk.User({
      id: _AddressTo.toLowerCase(),
      name: "Chat con " + _AddressTo.substring(0, 5) + "..." + _AddressTo.slice(-5),
      email: null,
      role: "sellers"
    })

    /*if(escrower)
      chatId = buyerAddress + "_" + window.ethereum.selectedAddress + "_0x47b833781a04ab68777f0c3881009b0103e3666a_" + txId
    else
      chatId = window.ethereum.selectedAddress + "_" + buyerAddress + "_" + txId
    */
    //let chatId = window.ethereum.selectedAddress + "_" + buyerAddress + "_" + txId
    var conversation = window.talkSession.getOrCreateConversation(
      Talk.oneOnOneId(me, to)
    )
    conversation.setParticipant(me)
    conversation.setParticipant(to)

    window.popupChat.select(conversation)
    window.popupChat.show()
  }

  useEffect(() => {
    const getHistory = async () => {
      const p2pContract = await web3Utils.getContract(await web3Utils.getP2PContractAddress(), web3Utils.p2pAbi);
      return await p2pContract.methods
        .getSellerOrdersToApproveOrActive(1, 20)
        .call({ from: window.ethereum.selectedAddress })
        .then(result => {
          setIsLoadingAction(false)
          if (result[0].length === 0 || result[1][0] === '0')
            setNotRecords(true)
          else
            setNotRecords(false)
          setHistory(parseList(result));
        })
        .catch(err => {
          console.log(err);
        });
    }

    web3Utils.getTokentList()
      .then(result => {
        let i = 0
        result.forEach(token => {
          namesByAddress[token.address] = token.name
          i++
          if (result.length === i) {
            getHistory()
          }
        })
      })

  }, []);


  return (
    <>
      <div className="container table-responsive">
        <table className="table table-primary table-striped table-hover">
          <caption>{t("history_purchasesales_offers")}</caption>
          <thead>
            <tr key="1">
              <th>{t("transaction")}</th>
              <th>{t("type")}</th>
              <th>{t("asset")}</th>
              <th>{t("state")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            
            {history.map((history, index) => {
              return (
                <tr key={index}>
                  <td>{history.id}</td>
                  <td>{history.typetrade}</td>
                  <td>{history.ammount} {history.coin} </td>
                  <td>{history.status_trade === "En Progreso" ? t("in_progress") : history.status_trade === "Completado" ? t("complete") : history.status_trade}</td>
                  <td>
                    {isLoadingAction === false && history.buyOrSell === 1 ?
                      <span><button onClick={() => fundRelease(window.ethereum.selectedAddress, history.id, history.buyer, history.coin)} className="btn btn-secondary">{t("approve")}</button><br /></span>
                      : ""} &nbsp;
                    {isLoadingAction === false ?
                      <span><button onClick={() => EscrowAction(window.ethereum.selectedAddress, history.id)} className="btn btn-secondary">{t("request_intervention")}</button><br /></span> : ""}
                    &nbsp;
                    {isLoadingAction === false && history.buyOrSell === 1 ? 
                      <span><button onClick={() => openChatWithBuyer(history.buyer, history.id, true)} className="btn btn-secondary">{t("chat")} {t("validating_agent")}</button><br /></span>
                      : ""} &nbsp;
                    {isLoadingAction === false && history.buyOrSell === 1 ?
                      <span><button onClick={() => openChatWithBuyer(history.buyer, history.id)} className="btn btn-secondary">{t("chat")} {t("buyer")}</button><br /></span>
                      : ""} &nbsp;
                    {isLoadingAction === false && history.buyOrSell === 0 && !localStorage.getItem("ID_" + history.id + "_" + history.buyer) ?
                      <span><button onClick={() => notifyPay(window.ethereum.selectedAddress, history.id, history.buyer, history.coin)} className="btn btn-secondary">{t("mark_deposit_done")}</button><br /></span>
                      : ""}
                    {isLoadingAction === true ?

                      <Puff
                        height="30"
                        width="30"
                        radisu={1}
                        color="#f8359e"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                      : ""}

                  </td>
                </tr>
              );
            })}
            {notRecords ? <tr><td colSpan={5}><h1 style={{ textAlign: "center" }}>{t("no_records")}</h1></td></tr> : ""}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
