import React from "react";
import HomePageCard from "../HomePageCard";
import btnhistorial from '../../assets/btn-historial.png';
import btnhcomprar from '../../assets/btn-comprar.png';
import btnperfil from '../../assets/btn-perfil.png';
import btnvender from '../../assets/btn-vender.png';
import btnofertacompra from '../../assets/btn-oferta-compra.png';
import btnofertaventa from '../../assets/btn-oferta-venta.png';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  return (
    <>
      <div className="banner-P2P"><h1>P2P</h1><h2>{t("buy_a_sell")} Sparklife</h2></div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <HomePageCard img={btnofertacompra} name={t("new_purchase_offer")} link="/SellOffers" navigate={navigate} />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <HomePageCard img={btnofertaventa} name={t("new_sale_offer")} link="/SellOffers" navigate={navigate} />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <HomePageCard img={btnhistorial} name={t("history")} link="/History" navigate={navigate} />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <HomePageCard img={btnvender} name={t("Sell")} link="/BuySps" navigate={navigate} />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <HomePageCard img={btnhcomprar} name={t("buy")} navigate={navigate} />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <HomePageCard img={btnperfil} name={t("profile")} link="/Profile" navigate={navigate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
