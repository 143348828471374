import React from "react";
import "../styles/Lists.css";
import Rating from "./Rating";
import { useTranslation } from 'react-i18next';

const Lists = ({ rate, userWallet }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        {/* <div>
          <h2>👨</h2>
        </div> */}
        <div className="row">
          <p className="col-12">
            {t("wallet")}:<b>{userWallet.substring(0, 5) + "..." + userWallet.slice(-5)}</b><br />
            {t("rating")}:<b>{rate}%</b><br />
          </p>
          <div className="col-12">
            <Rating percentage={rate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Lists;
