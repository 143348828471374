import React, { useState, useEffect } from "react";
import "../../styles/History.css";
import web3Utils from '../../Utils/web-utils'
import swal from 'sweetalert'
import imgNft from "../../assets/nftImagen.png"

// Validar si es vendedor y mostrar otras vistas
const MintNft = () => {
  const [viewEscrower, setViewEscrower] = useState(false);
  async function buyNft() {
    const p2pContract = await web3Utils.getContract(web3Utils.mfcNftContractCollection, web3Utils.nftCollectionAbi)
    await p2pContract.methods
      .mint('QmRMkBkNArPC484ovAjXP6vwYsCWQCYPUYYbj4ww3FRoZQ')
      .send({ value: '0', from: window.ethereum.selectedAddress })
      .on('transactionHash', hash => {
        console.log(hash)
      })
      .on('transactionHash', () => {
        console.log("Pending transaction... please wait")
      })
      .on('error', (err) => {
        console.log(err);
      })
      .then(receipt => {
        console.log(receipt)
        swal("¡Buen trabajo!", "La transacción se ha elevado a un Árbitro para que se tome una desición.", "success")
      })
  }
  useEffect(() => {
    // debugger
  }, []);
  
  return (
    <>
      <div className="History-Main-Container">
        <div className="History-Container">
         <h1 className="History-Tittle">Mintear un NFT de Medellín Fun City</h1>
         <div className="History-Table-Container">
            <div style={{textAlign:"center", width:"100%"}}>
              <img src={imgNft} style={{width:"30%"}} />
              <br/><br/>
              <button className="mint-btn" onClick={buyNft}>Obtener NFT</button>
            </div>
         </div>
        </div>
      </div>
    </>
  );
};

export default MintNft;