import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import logoSparklife from "../assets/logo-sparklife.svg";

const ParticlesBackground = () => {
    const particlesInit = useCallback(async engine => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        // await console.log(container);
    }, []);

    const options = {
        background: {
            color: {
                value: "#0d47a1",
            },
            opacity: 0.0
        },
        fpsLimit: 50,
        zLayers: 1,
        interactivity: {
            events: {
                onClick: {
                    enable: false,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
                resize: {
                    enable: true,
                    delay: 0.5,
                },
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 100,
                    duration: 1,
                },
            },
        },
        particles: {
            color: {
                enable: true,
                // value: ["#FA0E52", "#130854", "#7D1EE1", "#F9029C", "#FA79F7", "#FB9100", "#1065C8", "#35E0E7", "#C1FEFF"],
                value: "#fff",
                sync: false,
            },
            links: {
                enable: true,
                color: {
                    value: ["#FA0E52", "#130854", "#7D1EE1", "#F9029C", "#FA79F7", "#FB9100", "#1065C8", "#35E0E7", "#C1FEFF"],
                    sync: true,
                },
                distance: 300,
                // opacity: 0.5,
                width: 1,
            },
            collisions: {
                enable: true,
            },
            move: {
                enable: true,
                direction: "none",
                outModes: {
                    default: "bounce",
                },
                random: true,
                speed: 1,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                    area: 800,
                },
                value: 30,
            },
            opacity: {
                value: 0.7,
            },
            shape: {
                type: "image",
                options: {
                    image: {
                        src: logoSparklife,
                        replaceColor: true,
                        opacity: 0.5,
                    }
                },
            },
            size: {
                value: { min: 10, max: 15 },
            },
        },
        detectRetina: true,
    }

    return (
        <Particles id="tsparticles" init={particlesInit} loaded={particlesLoaded} options={options}></Particles>
    )
}

export default ParticlesBackground;