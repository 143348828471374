import spsTokenAbi from '../components/ABI/spsToken'
import p2pAbi from '../components/ABI/p2p'
import pancakeSwapAbi from '../components/ABI/pancakeSwapAbi'
import usdtAbi from '../components/ABI/usdt'
import busdAbi from '../components/ABI/busd'
import bcaAbi from '../components/ABI/bca'
import btcsAbi from '../components/ABI/btcs' // BTC Sparklife
import p2pBtcsWithdrawAbi from '../components/ABI/p2pBtcsWithdraw'
import btcbAbi from '../components/ABI/btcb'
import tokenListAbi from '../components/ABI/tokenList'
import sellerScrowerAbi from '../components/ABI/sellerScrower'
import referralAbi from '../components/ABI/referral'
import nftCollectionAbi from '../components/ABI/nftCollection'

const parseTokenList = (response) => {
  let result = []
  let totalRows = response[0].length
  for(let i = 0; i < totalRows; i++){
    result[i] = {}
    result[i]["id"] = parseInt(response[0][i])
    result[i]["name"] = web3Utils.toAscii(response[2][i])
    result[i]["address"] = response[1][i]
  }
  return result
}
// const p2pContractAddress = "0x02087EB859DC2D93c02fc6DFDb8798B3F2b467a9" //0x2dB543Caa2772e90b3f25C1F23A2D38DDae7d812
// const p2pContractAddressTron = "TY92SwKR2ff7QHJmpCqScPwxLWvfuyzYrP"
// const p2pTokenListAddress = "0xbcec4393944f8EA647979f27745D2184060EC80c" //
// const p2pTokenListAddressTron = "TNUYqLKiesVz4r7xk94qijiRfEKcm31bYy"
// const p2pSellerScrowerAddress = "0xb4E82944E839072730700Bc7be2dB615F019347A" //
// const p2pSellerScrowerAddressTron = "TH1D9uFAb2ToUvbj3Q4xWzQog5Q8QS5bJx"
// const p2pReferralAddress = "0x26B7BFC4e1A1171cd5A5EE8989101818798B7925" //
// const p2pReferralAddressTron = "TVSwXj4ABB8uSNUjbCf6cN1ZM9G23YWXPc"

const web3Utils = {
  p2pAbi,
  usdtAbi,
  busdAbi,
  bcaAbi,
  btcbAbi,
  btcsAbi,
  p2pBtcsWithdrawAbi,
  tokenListAbi,
  referralAbi,
  sellerScrowerAbi,
  pancakeSwapAbi,
  spsTokenAbi,
  nftCollectionAbi,

  toAscii: (value) => {
    if(web3Utils.getUserSesionData().provider == 3)
      return window.web3.toAscii(value).replace(/\x00/g, "")
    else
      return window.web3.utils.toAscii(value).replace(/\x00/g, "")
  },
  completeWithZero: (lgn1, lgn2) => {
    let zeros = ""
    for(let i = lgn1; i < lgn2; i++) {
      zeros += "0"
    }
    return zeros
  },
  fromAscii: (value) => {
    if(web3Utils.getUserSesionData().provider == 3)
      return window.web3.fromAscii(value)
    else
      return window.web3.utils.fromUtf8(value)
  },
  toWei: (value) => {
    if(web3Utils.getUserSesionData().provider == 3)
      return window.web3.trx.tronWeb.toSun(value)
    else
      return window.web3.utils.toWei(value, "ether")
  },
  getBalance: async () => {
    let account = web3Utils.getUserSesionData().account
    let balance = 0
    if(web3Utils.getUserSesionData().provider == 3)
      balance = await window.web3.trx.getBalance(account)/10**6
    else{
      balance = await window.web3.eth.getBalance(account)/10**18
    }

    balance = web3Utils.toWei(balance.toString())
    return balance
      
  },
  getBTCSBalance: async () => {
    let account = web3Utils.getUserSesionData().account
    let btcsContract = await web3Utils.getContract(await web3Utils.getBTCSTokenAddress(), web3Utils.btcsAbi);
    return await btcsContract.methods
      .balanceOf(account)
      .call()
      .then(async result => {
        return (result/10**18).toFixed(18)
      })
      .catch(() => {
        return 0
      })
  },
  toBN: (value) => {
    if(web3Utils.getUserSesionData().provider == 3)
      return window.web3.toBigNumber(value)
    else
      return window.web3.utils.toBN(value)
  },
  getTokenAddressById: (tokenId) => {
    
  },
  getDecimalsByTokenId: (tokenId) => {
    if(web3Utils.getUserSesionData().provider == 3) {
      return web3Utils.decimalTokenTrc[tokenId]
    }
    else {
      return web3Utils.decimalToken[tokenId]
    }
  },

  getP2PContractAddress: async () => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0xBaB102DD4BA3e16B44c275a6d540364D1cFB0A81")
        else
          resolve('')
      }
      else
        resolve('TGZ3B1mHBcv7DkD6NcfsoJbfTNNwq1f3v4') // TY92SwKR2ff7QHJmpCqScPwxLWvfuyzYrP
    })
  },
  getAmmountToApprove: () => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve('1000000000000000000000000')
        else
          resolve('1000000000000000')
      }
      else
        resolve('1000000000000000')
    })
  },
  getP2PReferralContractAddress: async () => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0x26B7BFC4e1A1171cd5A5EE8989101818798B7925") // resolve("0xb4151928707A16635F4cFE31734906E635ffE910") //
        else
          resolve('')
      }
      else
        resolve('TVSwXj4ABB8uSNUjbCf6cN1ZM9G23YWXPc')
    })
  },
  getP2PTokenListContractAddress: async () => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0xbcec4393944f8EA647979f27745D2184060EC80c") // resolve("0x32110c060f8A7c51b52FB9Bf9406ddEbfc723D56") //
        else
          resolve('')
      }
      else
        resolve('TBPV1bCg5zgDC7MsWSzPTT5gEPrUtrFCuJ') // TNUYqLKiesVz4r7xk94qijiRfEKcm31bYy
    })
  },
  getP2PSellerScrowerContractAddress: async () => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0xb4E82944E839072730700Bc7be2dB615F019347A") // resolve("0x68397E65e62b1806B20F547E7a21f92Cc99DeA8d") //
        else
          resolve('')
      }
      else
        resolve('TH1D9uFAb2ToUvbj3Q4xWzQog5Q8QS5bJx')
    })
  },
  getP2PWithdrawContractAddress: async () => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0x9db07614d70c30095D69b1EFfBb5cA2261fA54F2")
        else
          resolve('')
      }
      else
        resolve('')
    })
  },

  getSpsTokenAddress: async (isNative = false) => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0x8033064Fe1df862271d4546c281AfB581ee25C4A") // resolve("0x68397E65e62b1806B20F547E7a21f92Cc99DeA8d") //
        else
          resolve('')
      }
      else
        if(isNative)
          resolve('TTXcTeo8nR3qW5o2ErVjnTpZWzFqXmWkGb') // SPS token TRC20
        else
          resolve('41c09b0801d2114bf549075f40ddda0a2ec31f97f9')
    })
  },
  getUSDTTokenAddress: async (isNative = false) => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0x55d398326f99059fF775485246999027B3197955") // resolve("0x68397E65e62b1806B20F547E7a21f92Cc99DeA8d") //
        else
          resolve('')
      }
      else
        if(isNative)
          resolve('TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t') // USDT token TRC20
        else
          resolve('41a614f803b6fd780986a42c78ec9c7f77e6ded13c')
    })
  },
  getBTCSTokenAddress: async (isNative = false) => {
    return new Promise(async (resolve, reject) => {
      if(web3Utils.getUserSesionData().provider != 3) {
        if(await window.web3.eth.getChainId() == 56)
          resolve("0x1870579e822935F68Ea3f440feF8fe90c73C4a5D")
        else
          resolve('')
      }
      else
        if(isNative)
          resolve('') // USDT token TRC20
        else
          resolve('')
    })
  },

  //spsTokenAddress: "0x8033064Fe1df862271d4546c281AfB581ee25C4A", // "0x065B25C14DD5a308E67D0611Aa17E217b531487d", //,
  //usdtAddress: "0x55d398326f99059fF775485246999027B3197955", // "0xf554F9Ebb1336169B87cF153A08c2d7c0F48aBb8", //
  bcaAddress: "0x920E2fA169b2aF777CD1362c5825B3A264095534", // "0x577BbE5F0d0a3cB186299130f3BCd0Baf881631F", //
  busdAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", // "0xA43b1510E3c6AED00fa126B385419A0242785feA", //
  btcbAddress: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c", // "0x8246854165c3C5207159C6EAbd2FBa7786C3b15D", //

  mfcNftContractCollection: "0x8bf8f0faed753ff1325494fe9f08ec54c0e4ca42",

  // validar en TRX
  pancakeSwapContract: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  
  decimalToken: {
    0: 18,
    1: 18,
    2: 18,
    3: 18,
    4: 8,
    5: 18,
    6: 18,
    7: 18
  },
  decimalTokenTrc: {
    0: 18,
    1: 18,
    2: 6,
    3: 18,
    4: 8,
    5: 18,
    6: 18
  },
  getAbiByAddress: {
    "0x8033064Fe1df862271d4546c281AfB581ee25C4A":spsTokenAbi,
    "0x55d398326f99059fF775485246999027B3197955":usdtAbi,
    "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":busdAbi,
    "0x920E2fA169b2aF777CD1362c5825B3A264095534":bcaAbi,
    "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":btcbAbi,
    "0x1870579e822935F68Ea3f440feF8fe90c73C4a5D": btcsAbi,

    // TRC
    
    "0xC09B0801D2114bf549075F40DdDa0A2EC31F97f9": spsTokenAbi,
    "0xa614f803B6FD780986A42c78Ec9c7f77e6DeD13C": usdtAbi,
    
    "TTXcTeo8nR3qW5o2ErVjnTpZWzFqXmWkGb":spsTokenAbi,
    "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t":usdtAbi,
  },
  addressByToken: {
    sps: "0x8033064Fe1df862271d4546c281AfB581ee25C4A",
    usdt: "0x55d398326f99059fF775485246999027B3197955",
    busd: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    bca: "0x920E2fA169b2aF777CD1362c5825B3A264095534",
    btc: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    btcs: "0x1870579e822935F68Ea3f440feF8fe90c73C4a5D",

    // TRC
    spsTrxHex: "0xC09B0801D2114bf549075F40DdDa0A2EC31F97f9",
    usdtTrxHex: "0xa614f803B6FD780986A42c78Ec9c7f77e6DeD13C",
    
    spsTrx: "TTXcTeo8nR3qW5o2ErVjnTpZWzFqXmWkGb",
    usdtTrx: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  },
  getDecimalsByAddress: {
    "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c":18, // btcb
    "0x8033064Fe1df862271d4546c281AfB581ee25C4A":8,
    "0x55d398326f99059fF775485246999027B3197955":18,
    "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":18,
    "0x920E2fA169b2aF777CD1362c5825B3A264095534":18,
    "0x1870579e822935F68Ea3f440feF8fe90c73C4a5D":18,

    "0x0000000000000000000000000000000000000001":18,
    "0x0000000000000000000000000000000000000002":18,
    "410000000000000000000000000000000000000001":18,
    "410000000000000000000000000000000000000002":18,

    // TRC
    "41c09b0801d2114bf549075f40ddda0a2ec31f97f9": 8, // sps
    "41a614f803b6fd780986a42c78ec9c7f77e6ded13c": 6, // usdt

    // -- Native address
    "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t":6, // usdt
    "TTXcTeo8nR3qW5o2ErVjnTpZWzFqXmWkGb":8,
  },
  getContract: async (address, abi = null) => {

    if(!web3Utils.getUserSesionData().web3Init || !window.web3)
      return false

    if(web3Utils.getUserSesionData().provider == 3)
      return window.web3.contract().at(address)
    else
      return new window.web3.eth.Contract(abi, address)
  },
  getTokentList : () => {
    var p2pContract
    async function callFunction() {
      try {
        return await p2pContract.methods
          .getTokenList()
          .call()
          .then(result => {
            return parseTokenList(result)
          })
          .catch(err => {
            console.log(err);
          })  
      } catch (err) {
        alert('No se logró obtener un proveedor Web3, por favor desconecte su cuenta e ingrese nuevamente.')
        return []
      }
      
    }
    const getTokenList = async () => {
      // Mientras no esté inicializado el provider, seguir intentando
      if(!window.web3){
        var isInit = false
        while(!window.web3 || !isInit) {
          if(window.web3) {
            p2pContract = await web3Utils.getContract(await web3Utils.getP2PTokenListContractAddress(), web3Utils.tokenListAbi)
            isInit = !isInit
            return callFunction()
          } 
        }
      }
      else {
        p2pContract = await web3Utils.getContract(await web3Utils.getP2PTokenListContractAddress(), web3Utils.tokenListAbi)
        return callFunction()
      }
    }
    return getTokenList();
  },
  parseTokenList: parseTokenList,
  /**
  * Obtener los datos de la sesion
  * @returns Datos del usuario
  */
  getUserSesionData: () => {
    let data = JSON.parse(localStorage.getItem("usrData"))
    if(data !== "" && data !== null) {
      return JSON.parse(localStorage.getItem("usrData"))
    }
    else
      return {account: null, provider: 0, balance: 0, web3Init: 0}
 },
  getUserBalanceSessionData: () => {
    if(localStorage.getItem("usrData") !== "") {
      return JSON.parse(localStorage.getItem("usrData")).balance
    }
    else
      return 0
  },
  fotmat_number: (number, decimals, dec_point, thousands_sep) => {
    number = number.toFixed(decimals)
    
      var nstr = number.toString()
      nstr += ''
      let x = nstr.split('.')
      let x1 = x[0]
      let x2 = x.length > 1 ? dec_point + x[1] : ''
      var rgx = /(\d+)(\d{3})/
    
      while (rgx.test(x1))
          x1 = x1.replace(rgx, '$1' + thousands_sep + '$2')
    
      return x1 + x2
  }
}
export default web3Utils