import React from "react";
import "../styles/Rating.css"
// import { Tooltip } from "bootstrap";

const Rating = ({ percentage }) => {

    function icon(opacity) {
        return <svg className="rating-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 540">
            <path fill={"rgb(251, 145, 0, " + opacity + ")"} stroke="#000"
                d="M6.26,79.33c109.41,50.62,195.28,74,218.26,79.81a5,5,0,0,0,6-3.6c4.51-17.8,23.48-61.24,32.91-149.09a1.62,1.62,0,0,1,3.22,0c10.19,86.07,28.06,129.05,33,148.57a5.53,5.53,0,0,0,6.87,4c24.25-6.85,121.85-34.23,217.2-79.63a1.62,1.62,0,0,1,1.62,2.78C414.46,162.27,359,214.16,344.72,228.71a6.08,6.08,0,0,0-.47,8c9.82,12.68,41.79,46.42,88,88.08a1.62,1.62,0,0,1-1.59,2.75c-51.05-17.17-98.28-30.05-114.1-34.9-3.53-1.08-6.95,1-8.5,4.32-7,15.29-20.65,32.82-41.49,236.58a1.62,1.62,0,0,1-3.23,0c-20.59-201.21-33-223-42.85-238a7.37,7.37,0,0,0-7.93-3.1c-15.33,3.82-49.2,11.82-111.8,34.62a1.63,1.63,0,0,1-1.63-2.75c47.38-41.57,76.4-74.61,87.06-87.75a6.13,6.13,0,0,0-.36-8.13C170.16,212.2,111,157.08,4.65,82.12A1.62,1.62,0,0,1,6.26,79.33Z" /></svg>;
    }

    const stars = []
    for (let index = 80; index >= 0; index = index - 20) {
        let restPerc = percentage - index
        if (restPerc > 0) {
            stars.unshift(icon(restPerc / 20))
        } else {
            stars.unshift(icon(0))
        }
    }

    // const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //     new Tooltip(tooltipTriggerEl)
    // })


    return (
        <>
            {stars}
        </>
    );
};

export default Rating;